<template>
  <div style="min-height: 550px;" class="tab-pane fade p-2" :class="showTab">
    <div class="row mb-3">
      <div class="col-2">
        <button class="btn btn-sm btn-primary btn-block" @click="StepPrev()" :disabled="StepPrevDisabled">Geri</button>
      </div>
      <div class="col-8">
        <div class="progress" style="height: 32px;">
          <div class="progress-bar bg-success" role="progressbar" :style="{'width':ProgressBarRate+'%'}" v-bind:aria-valuenow="ProgressBarRate" aria-valuemin="0" aria-valuemax="100">{{ProgressBarTitle}}</div>
        </div>
      </div>
      <div class="col-2">
        <button class="btn btn-sm btn-primary btn-block" @click="StepNext()" :disabled="StepNextDisabled">Devam Et</button>
      </div>
    </div>
    <div v-if="step.index==1">
      <div class="row">
        <div class="col-7">
          <select class="custom-select mb-2" v-model="selectedPackage.packageId" @change="ChangePackage()">
            <option value="">Eğitim Paketi Seçiniz</option>
            <optgroup v-for="(g,index) in packages" :key="g" v-bind:label="PackageGroupName(index)">
              <option v-for="p in g" :key="p" v-bind:value="p.id">{{ p.packageTitle }}</option>
            </optgroup>
          </select>
          <div class="border rounded p-2 mb-2 overflow-auto" style="height:100px;">
            <div class="form-check border-bottom pb-1 pt-1" v-for="(c,index) in SelectedPackageInfo.packageCampaigns" :key="c">
              <input class="form-check-input" type="checkbox" v-model="selectedPackage.campaigns" v-bind:value="c.id" v-bind:id="'campaingchk'+index">
              <div class="row">
                <div class="col-1 text-sm">%{{ c.discountRate }}</div>
                <div class="col-8 text-sm">{{ c.title }}</div>
                <div class="col-3 text-sm">{{DateFormatCampaingFinishDate(c.finisDate)}}</div>
              </div>
            </div>
          </div>
          <div class="border rounded p-2 mb-2">
            <div class="row">
              <div class="col-6"></div>
              <div class="col-3 text-center text-bold text-sm">Paket</div>
              <div class="col-3 text-center text-bold text-sm">Kampanya</div>
            </div>
            <div class="row">
              <div class="col-6 text-sm">Ders Erteleme</div>
              <div class="col-3 text-center">{{SelectedPackageInfo.packageRights.postpone}}</div>
              <div class="col-3 text-center">0</div>
            </div>
            <div class="row">
              <div class="col-6 text-sm">Ders Dondurma</div>
              <div class="col-3 text-center">{{SelectedPackageInfo.packageRights.freezeLesson}}</div>
              <div class="col-3 text-center">0</div>
            </div>
          </div>
          <div class="border rounded p-2 overflow-auto" style="height:200px;">
            <div class="form-check">
              <div class="row border-bottom">
                <div class="col-4 text-bold text-sm">Taksit</div>
                <div class="col-4 text-bold text-sm">Aylık</div>
                <div class="col-4 text-bold text-sm">Toplam</div>
              </div>
            </div>
            <div class="form-check" v-for="t in SelectedPackageInfo.packageInstallments" :key="t">
              <input class="form-check-input" type="radio" v-model="selectedPackage.installmentCount" v-bind:value="t.installmentCount">
              <div class="row" v-if="t.installmentCount==1">
                <div class="col-4 text-sm">Tek Çekim</div>
                <div class="col-4 text-sm">{{t.mountAmount}}₺</div>
                <div class="col-4 text-sm">{{t.totalAmount}}₺</div>
              </div>
              <div class="row" v-else>
                <div class="col-4 text-sm">{{ t.installmentCount+' Taksit' }}</div>
                <div class="col-4 text-sm">{{ t.installmentCount + ' x ' + t.mountAmount }}₺</div>
                <div class="col-4 text-sm">{{t.totalAmount}}₺</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-5 border-left">
          <h4>Sipariş Özeti</h4>
          <div class="text-right">
            <div>Eğitim Paketi</div>
            <div class="text-bold">{{SelectedPackageInfo.title==''?'Eğitim Paketi Seçilmedi':SelectedPackageInfo.title}}</div>
          </div>
          <div class="text-right">
            <div>Eğitim Tutarı</div>
            <div class="h4">{{SelectedPackageInfo.packageAmount}}₺</div>
          </div>
          <div class="text-right">
            <div>İndirim</div>
            <div class="h4">{{SelectedPackageInfo.amountOfDiscount}}₺</div>
          </div>
          <div class="text-right">
            <div>Kdv Tutarı(%{{SelectedPackageInfo.taxRate}})</div>
            <div class="h4">{{SelectedPackageInfo.taxAmount}}₺</div>
          </div>
          <div class="text-right">
            <div>Vade Farkı Tutarı</div>
            <div class="h4">{{SelectedPackageInfo.amountOfInterest}}₺</div>
          </div>
          <div class="text-right">
            <div>Toplam Tutar</div>
            <div class="h2">{{SelectedPackageInfo.totalAmount}}₺</div>
          </div>
          <!-- <button type="button" class="btn btn-lg btn-primary btn-block">Devam Et</button> -->
        </div>
      </div>
    </div>
    <div v-if="step.index==2">
      <div class="row">
        <div class="col-5">
          <h6 class="text-center">{{SelectedPackageInfo.title==''?'Eğitim Paketi Seçilmedi':SelectedPackageInfo.title}}</h6>
          <h3 class="text-center">{{SelectedPackageInfo.totalAmount}}₺</h3>

          <div>
             <div class="form-group">
                <input type="text" class="form-control" placeholder="Kart Sahibi" maxlength="30" v-model="paymentForm.holderName" :disabled="paymentForm.isLoading"/>
              </div>
              <div class="form-group">
                <input type="text" class="form-control" v-maska="'####-####-####-####'" v-model="paymentForm.cardNumber"  :disabled="paymentForm.isLoading"/>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="form-group mb-1">
                    <input type="text" class="form-control" v-maska="'##/####'" v-model="paymentForm.expiryDate" :disabled="paymentForm.isLoading"/>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group mb-1">
                    <input type="text" class="form-control" v-maska="'###'" v-model="paymentForm.cvv" :disabled="paymentForm.isLoading"/>
                  </div>
                </div>
                <div class="col-5">
                  <input type="text" class="form-control" v-bind:value="selectedPackage.installmentCount==1?'Tek Çekim':selectedPackage.installmentCount+' Taksit'" disabled/>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 text-center">
                    <button type="submit" class="btn btn-primary btn-lg btn-block" @click="PayCC()" :disabled="paymentForm.isLoading">
                      <span class="ml-2">Ödeme Al</span>
                    </button>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 text-center">
                    <button type="submit" class="btn btn-secondary btn-lg btn-block" @click="PayMO()" :disabled="paymentForm.isLoading">
                      <span class="ml-2">Havale Olarak Ödeme Al<br/><small>({{ SelectedPackageInfo.totalAmountForMoneyOrder }}₺)</small></span>
                    </button>
                </div>
              </div>
          </div>


        </div>
        <div class="col-7">
          <h5 class="float-left">Başarılı Ödeme Listesi</h5>
          <button class="btn btn-sm btn-secondary float-right" @click="GetFreePayments()"><i class="fas fa-sync"></i></button>
          <div class="clearfix mb-1"></div>
          <div class="border rounded p-2 mb-2 overflow-auto" style="height:200px;">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Tarih</th>
                  <th scope="col">Tutar</th>
                  <th scope="col">Tür</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="p in freePayments" :key="p">
                  <th scope="row"><input type="checkbox" v-model="selectedPackage.payments" v-bind:value="p.id"></th>
                  <td>{{DateFormatPaymentDate(p.creationDate)}}</td>
                  <td>{{p.totalAmount.toFixed(2)}}₺</td>
                  <td>{{PaymentType(p.paymentMethod)}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h5 class="float-left">Fatura Adres Listesi</h5>
          <button class="btn btn-sm btn-secondary float-right" @click="GetBillingAddress"><i class="fas fa-sync"></i></button>
          <button class="btn btn-sm btn-primary float-right mr-1" @click="OpenCloseFastBillingAddressModal(true)">Hızlı Adres Oluştur</button>
          <div class="clearfix mb-1"></div>
          <div class="border rounded p-2 mb-2 overflow-auto" style="height:180px;">
            <div class="row border-bottom pb-1 mb-1" v-for="(b,index) in billingAddress" :key="b">
              <div class="col-1"><input type="radio" v-bind:id="'radio'+index" v-model="selectedPackage.billingId" v-bind:value="b.id"></div>
              <div class="col-11" v-bind:for="'radio'+index">
                  <div><small><b>{{b.title}}</b></small></div>
                  <div><small>{{b.address}} {{b.district}} / {{b.city}} / {{b.country}}</small></div>
                  <div><small>Posta Kodu : {{b.postCode}} | Vergi : {{b.taxOffice}} - {{b.taxNumber}}</small></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="step.index==3">
      <div class="row">
        <div class="col-6">
          <div>
            <div class="form-group">
              <input type="text" class="form-control" v-model="selectedPackage.skypeAddress" placeholder="Skype Adresi" />
            </div>
            <div class="form-group">
              <v-date-picker v-model="selectedPackage.startDate" mode="date" :min-date="new Date()">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
                    </div>
                    <input
                        :value="inputValue"
                        v-on="inputEvents"
                        class="form-control"
                        placeholder="Başlangıç Tarihi"
                      />
                  </div>
                </div>
                </template>
              </v-date-picker>
            </div>
            <div class="form-group">
              <select class="custom-select mb-2" v-model="selectedPackage.bookId">
                <option selected>Başlangıç Kitabını Seçiniz</option>
                <optgroup v-for="g in books" :key="g" v-bind:label="g.book.title">
                  <option v-for="l in g.levels" :key="l" v-bind:value="g.book.bookId+'#'+l.bookLevelId">{{ l.title==''?'Seviye Yok':l.title }} ({{ g.book.title }})</option>
                </optgroup>
              </select>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" v-model="selectedPackage.firstLessonLevelTest" id="firtLessonLevelTest">
                <label class="custom-control-label" for="firtLessonLevelTest" style="font-weight: normal;">İlk ders seviye belirleme olsun.</label>
              </div>
            </div>
            <button type="button" class="btn btn-lg btn-primary btn-block" :disabled="CreateTrainingDisabled" @click="CreateTraining()">Eğitimi Oluştur</button>
          </div>
          
        </div>
        <div class="col-6 border-left">
          <h4>Özet Bilgi</h4>
          <div class="text-right">
            <div>Eğitim Paketi</div>
            <div class="text-bold">{{SelectedPackageInfo.title}}</div>
          </div>
          <div class="text-right">
            <div>Ders Erteleme</div>
            <div class="h4">{{SelectedPackageInfo.packageRights.postpone}} Adet</div>
          </div>
          <div class="text-right">
            <div>Ders Dondurma</div>
            <div class="h4">{{SelectedPackageInfo.packageRights.freezeLesson}} Adet</div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <PaymentThreeDModal :ThreeUrl="paymentThreeUrl" :Visible="paymentThreeDModalVisible" @CloseModal="OpenClosePaymentThreeDModalModal($event)"></PaymentThreeDModal>
  <FastBillingAddressModal :Visible="fastBillingAddressModalVisible" :MemberId="MemberId" @CloseModal="OpenCloseFastBillingAddressModal($event)"></FastBillingAddressModal>
</template>
<style scoped>
  
</style>
<script>
import {mapActions} from "vuex";
import {SHOPPINGFILE_PAYMENT_URL} from "../../settings";
import {IsShowErrorMessage} from "../../helpers/error-message-helper";
import {ConvertTaxRateToNumber,CalculateRate,RoundFormatAmount} from "../../helpers/price-helper";
import {DateFormat,CalculateDateTime} from "../../helpers/date-convert-helper";
import {GetPaymentTypeText} from "../../helpers/enum-convert-helper";
import FastBillingAddressModal from "../FastBillingAddressModal.vue"
import PaymentThreeDModal from "../PaymentThreeDModal.vue"
export default {
  name: 'TabTrainingCreate',
  emits: ['AfterTrainingCreate'],
  components: { 
    FastBillingAddressModal,
    PaymentThreeDModal
  },
  props: {
    MemberId:{
      type:String,
      default:null
    },
    AccountId:{
      type:String,
      default:null
    },
    Visible:{
      type:Boolean,
      default:false
    },
  },
  data() {
    return {
      showTab:'',
      fastBillingAddressModalVisible:false,
      paymentThreeDModalVisible:false,
      createPackageLoading:false,
      paymentThreeUrl:'',
      step:{
        index:1,
        maxLength:3,
      },
      selectedPackage:{
        packageId:'',
        installmentCount:1,
        campaigns:[],
        payments:[],
        billingId:'',
        startDate:'',
        skypeAddress:'',
        bookId:'',
        firstLessonLevelTest:false
      },
      paymentForm:{
        isLoading:false,
        holderName:'',
        cardNumber:'',
        expiryDate:'',
        cvv:''
      },
      packages:[],
      books:[],
      billingAddress:[],
      freePayments:[]
    }
  },
  computed:{
    SelectedPackageInfo(){
      let result={
        title:'',
        packageAmount:RoundFormatAmount(0),
        amountOfDiscount:RoundFormatAmount(0),
        rateOfDiscount:RoundFormatAmount(0),
        taxAmount:RoundFormatAmount(0),
        taxRate:0,
        amountOfInterest:RoundFormatAmount(0),
        rateOfInterest:0,
        totalAmount:RoundFormatAmount(0),
        totalAmountForMoneyOrder:RoundFormatAmount(0),
        packageRights:{
          postpone:0,
          freezeLesson:0
        },
        packageCampaigns:[],
        packageInstallments:[]
      };
      if(this.selectedPackage.packageId){
        let packages = [];
        for(let key in this.packages){
          let obj = this.packages[key];
          for(let prop in obj){
            packages.push(obj[prop]);
          }
        }
        
        let selectedPackage = packages.filter(x=>x.id==this.selectedPackage.packageId);
        if(selectedPackage){
          result.title=selectedPackage[0].packageTitle;
          result.packageAmount=RoundFormatAmount(selectedPackage[0].price.baseFare);
          result.amountOfDiscount=RoundFormatAmount(0);
          result.taxAmount=RoundFormatAmount(CalculateRate(selectedPackage[0].price.baseFare,selectedPackage[0].price.taxRate));
          result.taxRate=ConvertTaxRateToNumber(selectedPackage[0].price.taxRate);
          result.amountOfInterest=RoundFormatAmount(0);
          result.rateOfInterest=RoundFormatAmount(0);
          result.totalAmount=RoundFormatAmount(parseFloat(result.packageAmount) + parseFloat(result.taxAmount));
          result.totalAmountForMoneyOrder=RoundFormatAmount(parseFloat(result.packageAmount) + parseFloat(result.taxAmount));


          result.packageRights.postpone=selectedPackage[0].packageRight.postpone;
          result.packageRights.freezeLesson=selectedPackage[0].packageRight.freezeLesson;

          for(let key in selectedPackage[0].campaigns){
            let obj = selectedPackage[0].campaigns[key];
            result.packageCampaigns.push(obj);
          }

          let selectedCampaigns = [];
          for(let key in this.selectedPackage.campaigns){
            let obj = this.selectedPackage.campaigns[key];
            let findPackage = result.packageCampaigns.find(x=>x.id==obj);
            if(findPackage){
              selectedCampaigns.push(findPackage); 
            }
          }
          result.packageInstallments = this.GetPackageInstallments(selectedPackage[0].price,selectedCampaigns);
          var moneyOrder = result.packageInstallments.find(x=>x.installmentCount==1);
          if(moneyOrder){
            result.totalAmountForMoneyOrder = RoundFormatAmount(moneyOrder.totalAmount);
          }
          var selectInstallment = result.packageInstallments.find(x=>x.installmentCount==this.selectedPackage.installmentCount);
          if(selectInstallment){
            result.taxAmount = RoundFormatAmount(selectInstallment.taxAmount);
            result.amountOfDiscount=(-1*RoundFormatAmount(selectInstallment.amountOfDiscount));
            result.amountOfInterest=RoundFormatAmount(selectInstallment.amountOfInterest);
            result.rateOfInterest=RoundFormatAmount(selectInstallment.rateOfInterest);
            result.totalAmount = RoundFormatAmount(selectInstallment.totalAmount);
            result.rateOfDiscount = RoundFormatAmount(selectInstallment.rateOfDiscount);
          }

        }
      }
      return result;
    },
    StepPrevDisabled:function(){
        return this.step.index<=1;
    },
    StepNextDisabled:function(){
      let result = true;  
      if(this.step.index===1){
        result = (this.selectedPackage.packageId=='')
      }else if(this.step.index===2){
        result = (this.selectedPackage.payments.length==0 || this.selectedPackage.billingId=='')
      }else if(this.step.index===3){
        result =true; //(this.selectedPackage.payments.length==0 && this.selectedPackage.billingId=='')
      }
      
      return result;//(this.selectedPackage.packageId=='') || (this.step.index>=this.step.maxLength);
    },
    CreateTrainingDisabled:function(){
      return this.selectedPackage.skypeAddress=='' || this.selectedPackage.bookId=='' || this.selectedPackage.startDate=='' || this.createPackageLoading
    },
    ProgressBarRate:function(){
      return (100 * this.step.index) / this.step.maxLength;
    },
    ProgressBarTitle:function(){
      let title = '';
      if(this.step.index==1)
      {
        title = ' ( Paket Seçimi )';
      }else if(this.step.index==2)
      {
        title = ' ( Ödeme )';
      }else if(this.step.index==3)
      {
        title = ' ( Özet )';
      }
      return  this.step.index + ' / '+ this.step.maxLength + title;
    }
  },
  methods:{
    ...mapActions(["MEMBERPROFILE_GETPACKAGES","MEMBERPROFILE_GETBOOKS",
    "MEMBERPROFILE_BILLINGADDRESS","PAYMENTINTERNALNEW","MEMBERPROFILE_FREEPAYMENTS",
    "MEMBERPROFILE_CREATEPACKAGE"]),
    // CalculateDateTimeByOffset(value){
    //     return CalculateDateTime(value);
    // },
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    DateFormatCampaingFinishDate(value){
      return DateFormat(value,'dd.mm.yyyy');
    },
    DateFormatPaymentDate(value){
      return DateFormat(CalculateDateTime(value),'dd.mm.yyyy hh:MM:ss');
    },
    PaymentType(value){
      return GetPaymentTypeText(value);
    },
    GetPackageInstallments(price,campaigns){
      let result=[];
      let packageBaseFare = 0;
      let taxRate = 0
      let rateOfInteret = 0
      let amountOfInteret = 0;
      let rateOfDiscount=0;
      let amountOfDiscount = 0;
      let rateOfInterets = [];
      if(price){
        packageBaseFare = price.baseFare;
        taxRate = price.taxRate;
        rateOfInterets.push(0);
        rateOfInterets.push(price.lateCharge.twoTax);
        rateOfInterets.push(price.lateCharge.threeTax);
        rateOfInterets.push(price.lateCharge.fourTax);
        rateOfInterets.push(price.lateCharge.fiveTax);
        rateOfInterets.push(price.lateCharge.sixTax);
        rateOfInterets.push(price.lateCharge.sevenTax);
        rateOfInterets.push(price.lateCharge.eightTax);
        rateOfInterets.push(price.lateCharge.nineTax);
        rateOfInterets.push(price.lateCharge.tenTax);
        rateOfInterets.push(price.lateCharge.elevenTax);
        rateOfInterets.push(price.lateCharge.twelveTax);
      }
      if(campaigns && campaigns.length>0){
        for(let key in campaigns){
          let obj = campaigns[key];
          rateOfInterets[1] = rateOfInterets[1] > obj.price.lateCharge.twoTax?rateOfInterets[1]:obj.price.lateCharge.twoTax;
          rateOfInterets[2]= rateOfInterets[2] > obj.price.lateCharge.threeTax?rateOfInterets[2]:obj.price.lateCharge.threeTax;
          rateOfInterets[3]= rateOfInterets[3] > obj.price.lateCharge.fourTax?rateOfInterets[3]:obj.price.lateCharge.fourTax;
          rateOfInterets[4]= rateOfInterets[4] > obj.price.lateCharge.fiveTax?rateOfInterets[4]:obj.price.lateCharge.fiveTax;
          rateOfInterets[5]= rateOfInterets[5] > obj.price.lateCharge.sixTax?rateOfInterets[5]:obj.price.lateCharge.sixTax;
          rateOfInterets[6]= rateOfInterets[6] > obj.price.lateCharge.sevenTax?rateOfInterets[6]:obj.price.lateCharge.sevenTax;
          rateOfInterets[7]= rateOfInterets[7] > obj.price.lateCharge.eightTax?rateOfInterets[7]:obj.price.lateCharge.eightTax;
          rateOfInterets[8]= rateOfInterets[8] > obj.price.lateCharge.nineTax?rateOfInterets[8]:obj.price.lateCharge.nineTax;
          rateOfInterets[9]= rateOfInterets[9] > obj.price.lateCharge.tenTax?rateOfInterets[9]:obj.price.lateCharge.tenTax;
          rateOfInterets[10]= rateOfInterets[10] > obj.price.lateCharge.elevenTax?rateOfInterets[10]:obj.price.lateCharge.elevenTax;
          rateOfInterets[11]= rateOfInterets[11] > obj.price.lateCharge.twelveTax?rateOfInterets[11]:obj.price.lateCharge.twelveTax;
          
          if(parseFloat(rateOfDiscount)<1.0){
            rateOfDiscount +=obj.price.discountRate;
          }
          
        }
      }
      let baseFare = 0;
      let taxAmount = 0;
      for(let i=1;i<=12;i++){
        rateOfInteret = rateOfInterets[i-1];
        amountOfDiscount = CalculateRate(parseFloat(packageBaseFare),rateOfDiscount.toFixed(2));
        baseFare = parseFloat(packageBaseFare) - parseFloat(amountOfDiscount);
        amountOfInteret = CalculateRate(parseFloat(baseFare),rateOfInteret);
        taxAmount = CalculateRate((parseFloat(baseFare)+parseFloat(taxAmount)),taxRate);
        
        result.push({
          installmentCount:i,
          amountOfInterest:RoundFormatAmount(parseFloat(amountOfInteret)),
          amountOfDiscount:RoundFormatAmount(parseFloat(amountOfDiscount)),
          rateOfDiscount:RoundFormatAmount(parseFloat(rateOfDiscount)),
          taxAmount:RoundFormatAmount(parseFloat(taxAmount)),
          rateOfInterest:rateOfInteret,
          mountAmount:RoundFormatAmount((parseFloat(baseFare)+parseFloat(taxAmount)+parseFloat(amountOfInteret))/i),
          totalAmount:RoundFormatAmount(parseFloat(baseFare)+parseFloat(taxAmount)+parseFloat(amountOfInteret)),
          totalAmountForMoneyOrder:RoundFormatAmount(parseFloat(baseFare)+parseFloat(taxAmount)+parseFloat(amountOfInteret))
        });
      }
      return result;
    },
    PackageGroupName(value){
      if(value==0){
        return "Kendini Dene Paketleri"
      }else if(value==2){
        return "2 Aylık Paketler"
      }else if(value==4){
        return "4 Aylık Paketler"
      }else if(value==6){
        return "6 Aylık Paketler"
      }else if(value==12){
        return "12 Aylık Paketler"
      }else{
        return "";
      }
    },
    ChangePackage(){
        this.selectedPackage.installmentCount=1;
        this.selectedPackage.campaigns=[];
    },
    GetInitData(){
      this.GetPackages();
      this.GetBooks();
      this.GetBillingAddress();
      this.GetFreePayments();
      this.Init();
    },
    Init(){
      this.step.index=1;
      this.step.maxLength=3;
      this.selectedPackage.packageId='';
      this.selectedPackage.installmentCount=1;
      this.selectedPackage.campaigns=[];
      this.selectedPackage.payments=[];
      this.selectedPackage.billingId='';
      this.selectedPackage.startDate='';
      this.selectedPackage.skypeAddress='';
      this.selectedPackage.bookId='';
      this.selectedPackage.firstLessonLevelTest=false;

      this.paymentForm.holderName='';
      this.paymentForm.cardNumber='';
      this.paymentForm.expiryDate='';
      this.paymentForm.cvv='';
      this.paymentForm.isLoading=false;
      this.createPackageLoading=false;

    },
    GetPackages(){
      this.MEMBERPROFILE_GETPACKAGES()
      .then((res)=>{
        this.packages = res.data;
      })
      .catch(error=>{
        if(this.IsShowErrorMessage(error)){
            this.$toast.error(error,{
                position : 'top',
                duration : 3000
            });
          }
      });
    },
    GetBooks(){
      this.MEMBERPROFILE_GETBOOKS()
      .then((res)=>{
        this.books = res.data;
      })
      .catch(error=>{
        if(this.IsShowErrorMessage(error)){
            this.$toast.error(error,{
                position : 'top',
                duration : 3000
            });
          }
      });
    },
    GetBillingAddress(){
      this.MEMBERPROFILE_BILLINGADDRESS({id:this.MemberId})
      .then((res)=>{
        this.billingAddress = res.data;
      })
      .catch(error=>{
        if(this.IsShowErrorMessage(error)){
            this.$toast.error(error,{
                position : 'top',
                duration : 3000
            });
          }
      });
    },
    GetFreePayments(){
      this.MEMBERPROFILE_FREEPAYMENTS({id:this.MemberId})
      .then((res)=>{
        this.freePayments = res.data;
      })
      .catch(error=>{
        if(this.IsShowErrorMessage(error)){
            this.$toast.error(error,{
                position : 'top',
                duration : 3000
            });
          }
      });
    },
    StepNext(){
      this.step.index++;
    },
    StepPrev(){
      this.step.index--;
    },
    OpenCloseFastBillingAddressModal(value){
      this.fastBillingAddressModalVisible=value;
    },
    OpenClosePaymentThreeDModalModal(value,url){
      this.paymentThreeUrl=url;
      this.paymentThreeDModalVisible=value;
    },
    PaymentFormValidate(){
        let amount = parseFloat(this.SelectedPackageInfo.totalAmount);
        if(amount<1 || amount>99999.99){
            alert('1₺ ile 99.999,99₺ arasında çekim yapabilirsiniz.');
            return false;
        }

        if(this.paymentForm.holderName.length<3){
            alert('Kartı sahibinin adını girmelisiniz.');
            return false;
        }

        if(this.paymentForm.cardNumber.length!=19){
            alert('Kartı numarası hatalı.');
            return false;
        }

        if(this.paymentForm.expiryDate.length!=7){
            alert('Kartın son kullanım tarihi yanlış formatta girdiniz. Ör: 12/2025');
            return false;
        }
        let expiryMonth = parseInt(this.paymentForm.expiryDate.substring(0,2),10);
        let expiryYear = parseInt(this.paymentForm.expiryDate.substring(3,7),10);

        if(expiryMonth<1 || expiryMonth>12){
            alert('Kartın son kullanım tarihini yanlış girdiniz.');
            return false;
        }
        if(expiryYear<new Date().getFullYear()){
            alert('Kartın son kullanım tarihini yanlış girdiniz.');
            return false;
        }

        this.paymentForm.cvv
        if(this.paymentForm.cvv.length<3){
            alert('Kartın güvenlik kodu hatalı');
            return false;
        }

        return true;
    },
    PayCC(){
      //return;
      let isValid = this.PaymentFormValidate();
      if(!isValid) {return;}
      if(!confirm(this.SelectedPackageInfo.totalAmount + '₺ kredi kartından çekim yapmak istiyor musunuz?')) {return;}

      let model = {
         paymentMethod:1,
         platform: 3,
         memberId:this.MemberId,
         amount:this.SelectedPackageInfo.totalAmount - this.SelectedPackageInfo.amountOfInterest,
         amountOfInterest:this.SelectedPackageInfo.amountOfInterest,
         rateOfInterest:this.SelectedPackageInfo.rateOfInterest,
         totalAmount:this.SelectedPackageInfo.totalAmount,
         currency:3,
         creditCardInfo:{
             cardNumber: this.paymentForm.cardNumber,
             cardHolder: this.paymentForm.holderName,
             cardExpireDate: this.paymentForm.expiryDate,
             cardCvv: this.paymentForm.cvv,
             installment: this.selectedPackage.installmentCount,
             isThreeD: true,
         }
      }
      
      this.PAYMENTINTERNALNEW(model)
      .then((result)=>{
         let threeDUrl = SHOPPINGFILE_PAYMENT_URL + '?q=' + result.data.paymentId;
         this.OpenClosePaymentThreeDModalModal(true,threeDUrl);
      })
      .catch(error=>{
       if(this.IsShowErrorMessage(error)){
        this.$toast.error(error,{
                position : 'top',
                duration : 3000
            });
        }
      });
    },
    PayMO(){
      //return;
      if(!confirm(this.SelectedPackageInfo.totalAmountForMoneyOrder + '₺ havale olarak ödeme yapmak istiyor musunuz?')) {return;}
      let model = {
         paymentMethod:2,
         platform: 3,
         memberId:this.MemberId,
         amount:this.SelectedPackageInfo.totalAmountForMoneyOrder,
         amountOfInterest:0,
         rateOfInterest:0,
         totalAmount:this.SelectedPackageInfo.totalAmountForMoneyOrder,
         currency:3,
      }
      this.PAYMENTINTERNALNEW(model)
      .then(()=>{
       this.$toast.success("Ödeme başarıyla oluşturuldu.",{
               position : 'top',
               duration : 3000
         });
         //let threeDUrl = PAYMENT_URL + '?q=' + result.data.paymentId;
         //this.OpenClosePaymentThreeDModalModal(true,threeDUrl);
      })
      .catch(error=>{
       if(this.IsShowErrorMessage(error)){
        this.$toast.error(error,{
                position : 'top',
                duration : 3000
            });
        }
      });
    },
    CreateTraining(){
    //  return;
      this.createPackageLoading=true;
      if(!confirm('"'+this.SelectedPackageInfo.title + '" eğitim paketini oluşturmak istiyor musunuz?')) {
        this.createPackageLoading=false;
        return;
      }

      var model = {
        accountId:this.AccountId,
        memberId:this.MemberId,
        packageId:this.selectedPackage.packageId,
        campaigns:this.selectedPackage.campaigns,
        payments:this.selectedPackage.payments,
        billingId:this.selectedPackage.billingId,
        installmentCount:this.selectedPackage.installmentCount,
        startDate:DateFormat(this.selectedPackage.startDate,'yyyy-mm-dd'),
        startBook:this.selectedPackage.bookId,
        skypeAddress:this.selectedPackage.skypeAddress,
        firstLessonLevelTest:this.selectedPackage.firstLessonLevelTest,
        rights : {
          postpone:this.SelectedPackageInfo.packageRights.postpone,
          freezeLesson:this.SelectedPackageInfo.packageRights.freezeLesson
        },
        prices :{
          baseAmount:parseFloat(this.SelectedPackageInfo.packageAmount),
          discountRate:parseFloat(this.SelectedPackageInfo.rateOfDiscount),
          discountAmount:parseFloat(this.SelectedPackageInfo.amountOfDiscount),
          taxRate:parseFloat(this.SelectedPackageInfo.taxRate),
          taxAmount:parseFloat(this.SelectedPackageInfo.taxAmount),
        }
      };

      this.MEMBERPROFILE_CREATEPACKAGE(model)
      .then((res)=>{
        this.$toast.success(res.data.description,{
                position : 'top',
                duration : 3000
        });
        this.$emit("AfterTrainingCreate");
      })
      .catch(error=>{
        this.createPackageLoading=false;
        if(this.IsShowErrorMessage(error)){
         this.$toast.error(error,{
                 position : 'top',
                 duration : 3000
             });
         }
         
      });

    }
  },
  watch: {
    Visible(value){
      if(value && this.MemberId){
        this.showTab='show active';
        this.GetInitData();
      }else{
        this.showTab='';
      }
    }
  },
  mounted: function() {

  }
  
}
</script>