<template>
  <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Eğitmen ve Öğrenci Listesi</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{path:'/'}">Anasayfa</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{path:'/panel/reports'}">Raporlar</router-link>
              </li>
              <li class="breadcrumb-item active">Eğitmen ve Öğrenci Listesi</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
      <div class="container-fluid">        
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div v-if="isDataLoading" class="text-center p-2">Lütfen bekleyin</div>
                <div v-else id="accordion">
                  <div class="d-block clearfix mb-3">
                    <div class="form-check float-right">
                      <input class="form-check-input" type="checkbox" v-model="showPassiveTeacher" v-bind:id="'ShowPassiveTeacher'" @change="ShowReport()">
                      <label class="form-check-label" v-bind:for="'ShowPassiveTeacher'">
                        Pasif öğretmenleri göster.
                      </label>
                    </div>
                  </div>
                  <div class="card card-lightblue" v-for="t in reportShow" :key="t">
                    <div class="card-header">
                      <h4 class="card-title w-100">
                        <a class="d-block w-100" data-toggle="collapse" v-bind:href="'#Teacher'+t.teacherId">
                          <div class="clearfix">
                            <div class="float-left">
                              {{ TeacherFullName(t.name,t.surname,t.nickname)}} 
                              <span class="badge" :class="{'badge-danger':t.status==0,'badge-success':t.status==1}">{{Status(t.status)}}</span>
                            </div>
                            <div class="float-right" v-html="CalcLessonDuration(t.students)"></div>
                          </div>
                          
                        </a>
                      </h4>
                    </div>
                    <div v-bind:id="'Teacher'+t.teacherId" class="collapse" data-parent="#accordion">
                      <div class="card-body">
                        <table class="table">
                            <thead>
                              <tr>
                                <th class="text-center" style="width:120px;">Eğitim Durumu</th>
                                <th class="text-center" style="width:120px;">Ders Durumu<br>{{ lessonDate }}</th>
                                <th class="text-center" style="width:120px;">Ders Saati</th>
                                <th style="width:250px;">Eğitim Paketi</th>
                                <th class="text-center" style="width:100px;">Kalan Ders Adedi</th>
                                <th>Öğrenci</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="s in t.students" :key="s">
                                <td class="text-center" :class="{'text-danger':s.trainingFile.status==0,'text-success':s.trainingFile.status==1}">{{ TrainingFileStatus(s.trainingFile.status) }}</td>
                                <td class="text-center">{{ ScheduleStatusText(s.trainingFile.lesson) }}</td>
                                <td class="text-center">{{ CalculateTimeByOffset(s.trainingFile.time) }}</td>
                                <td>{{ TrainingFilePackageName(s.trainingFile.month,s.trainingFile.duration,s.trainingFile.weekDay) }}</td>
                                <td class="text-center">{{ s.trainingFile.numberOfLessons }}</td>
                                <td>{{ s.name + ' '+ s.surname + ' ('+s.phoneNumber+')' }}</td>
                              </tr>
                            </tbody>
                        </table>
                        
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>

<script>
import {mapActions} from "vuex";
import {IsShowErrorMessage} from "../../../helpers/error-message-helper";
import {GetStatusText,GetTrainingFileStatus,GetScheduleStatusText} from "../../../helpers/enum-convert-helper";
import {CalculateTime} from "../../../helpers/date-convert-helper";
import {DateFormat} from "../../../helpers/date-convert-helper";

export default {
  name: "TeacherWithStudent",
  components: {
  },
  data(){
    return{
      isDataLoading:false,
      lessonDate:'',
      reportList:[],
      reportShow:[],
      showPassiveTeacher:false
    }
  },
  computed:{
    
  },
  watch:{
  },
  methods: {
    ...mapActions(["TEACHERWITHSTUDENTREPORT"]),
    Status(value){
      return GetStatusText(value);
    },
    LessonViewDateFormat(value){
      return DateFormat(value,'dd.mm.yyyy');
    },
    CalculateTimeByOffset(value){
        return CalculateTime(value);
    },
    TrainingFilePackageName(m,d,w){
      if(m==0)
        return d+' Dakikalık Kendini Dene';

      return m+' Ay '+d +' Dakika Haftada '+ w +' gün'; 
    },
    ScheduleStatusText(value){
      if(value==null)
        return 'Ders Yok';

      return GetScheduleStatusText(value.lessonStatus);
    },
    TrainingFileStatus(value){
      return GetTrainingFileStatus(value);
    },
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    TeacherFullName(name,surname,nickname){
      return (name + ' '+ surname) + (nickname!=null?' ('+nickname+')':'');
    },
    ShowReport(){
      let filter = /^true$/i.test(this.showPassiveTeacher);
      this.reportShow = filter?this.reportList:this.reportList.filter(x=>x.status==1);
    },
    GetReport(){
      let mainThis = this;
      mainThis.isDataLoading = true;
      mainThis.reportList = [];
      mainThis.TEACHERWITHSTUDENTREPORT()
      .then((res)=>{
        for (let index = 0; index < res.data.length; index++) {
            let l = res.data[index].students.find(x=>x.trainingFile.lesson!=null);
            if(l){
              mainThis.lessonDate = mainThis.LessonViewDateFormat(l.trainingFile.lesson.date);
              break;
            }
        }
        mainThis.reportList=res.data;
        mainThis.ShowReport();
        mainThis.isDataLoading = false;
      }).catch(error=>{
          mainThis.isDataLoading = false;
           if(this.IsShowErrorMessage(error)){
             this.$toast.error(error,{
                 position : 'top',
                 duration : 3000
             });
           }
        });
    },
    CalcLessonDuration(students){
      let duration2=0;
      let duration3=0
      for (let i = 0; i < students.length; i++) {
         if(students[i].trainingFile.weekDay==2){
            duration2 = duration2 + students[i].trainingFile.duration;
          }else if(students[i].trainingFile.weekDay==3){
            duration3 = duration3 + students[i].trainingFile.duration;
          }else if(students[i].trainingFile.weekDay==5){
            duration2 = duration2 + students[i].trainingFile.duration;
            duration3 = duration3 + students[i].trainingFile.duration;
          }
      }
      return '2 Gün: '+duration2+' Dakika | 3 Gün: '+duration3+' Dakika';
    },
  },
  mounted: function() {
    this.GetReport();
  }
};
</script>
<style scoped>
</style>