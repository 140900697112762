<template>
  <div style="min-height: 550px;" class="tab-pane fade p-2" :class="showTab">
    <h4 class="text-center">Paket Değişikliği ve Uzatma</h4>
    <div class="row mb-4">
      <div class="col-12">
        <h5>Mevcut Paket Bilgileri</h5>
        <div class="row">
            <div class="col-2">
              <div class="border rounded-lg bg-light text-center">
                <span class="d-block">Eğitim Süresi</span>
                <span class="d-block h3">{{ TrainingInfo.trainingMonth }} Ay</span>
              </div>
            </div>
            <div class="col-2">
              <div class="border rounded-lg bg-light text-center">
                <span class="d-block">Ders Süresi</span>
                <span class="d-block h3">{{ TrainingInfo.trainingDuration }} Dk</span>
              </div>
            </div>
            <div class="col-2">
              <div class="border rounded-lg bg-light text-center">
                <span class="d-block">Haftada</span>
                <span class="d-block h3">{{ TrainingInfo.weeklyTrainingDay }} Gün</span>
              </div>
            </div>
            <div class="col-2">
              <div class="border rounded-lg bg-light text-center">
                <span class="d-block">Toplam Ders</span>
                <span class="d-block h3">{{ TrainingInfo.lessonTotalCount }} Ad.</span>
              </div>
            </div>
            <div class="col-2">
              <div class="border rounded-lg bg-light text-center">
                <span class="d-block">Kalan Ders</span>
                <span class="d-block h3">{{ TrainingInfo.lessonWaitCount }} Ad.</span>
              </div>
            </div>
            <div class="col-2">
              <div class="border rounded-lg bg-light text-center">
                <span class="d-block">Kalan Ert.</span>
                <span class="d-block h3">{{ TrainingInfo.postpone }} Ad.</span>
              </div>
            </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h5>Paket Bilgileri</h5>
        <div class="row">
          <div class="col-5">
            <select class="custom-select mb-2" v-model="selectedPackage.packageId" @change="ChangePackage()">
              <option value="">Eğitim Paketi Seçiniz</option>
              <optgroup v-for="(g,index) in packages" :key="g" v-bind:label="PackageGroupName(index)">
                <option v-for="p in g" :key="p" v-bind:value="p.id">{{ p.packageTitle }}</option>
              </optgroup>
            </select>
          </div>
          <div class="col-7 pt-1">
              <div class="custom-control custom-switch custom-control-inline">
                <input type="radio" class="custom-control-input" name="SaleMethod" id="customSwitch1">
                <label class="custom-control-label" for="customSwitch1">Yeni Paket</label>
              </div>
              <div class="custom-control custom-switch custom-control-inline">
                <input type="radio" class="custom-control-input" name="SaleMethod" id="customSwitch2">
                <label class="custom-control-label" for="customSwitch2">Uzatma</label>
              </div>
              <div class="custom-control custom-switch custom-control-inline">
                <input type="radio" class="custom-control-input" name="SaleMethod" id="customSwitch3">
                <label class="custom-control-label" for="customSwitch3">Paket Değişikliği</label>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
  
</style>
<script>
import {mapActions} from "vuex";
import {IsShowErrorMessage} from "../../helpers/error-message-helper";
export default {
  name: 'TabTrainingChangePackage',
  //emits: [''],
  components: { 
  },
  props: {
    MemberId:{
      type:String,
      default:null
    },
    TrainingFileInfo:{
      type:Object,
      default:null
    },
    Visible:{
      type:Boolean,
      default:false
    },
  },
  data() {
    return {
      showTab:'',
      createPackageLoading:false,
      selectedPackage:{
        packageId:'',
        installmentCount:1,
        campaigns:[],
        payments:[],
        billingId:'',
        startDate:'',
        skypeAddress:'',
        bookId:'',
        firstLessonLevelTest:false
      },
      packages:[]
    }
  },
  computed:{
    TrainingInfo(){
        let result = {
          trainingFileId:'',
          lessonTotalCount:0,
          lessonWaitCount:0,
          postpone:0,
          freezeLesson:0,
          trainingMonth:0,
          trainingDuration:0,
          weeklyTrainingDay:0
        };

        if(this.TrainingFileInfo!=null){
          result.trainingFileId = this.TrainingFileInfo.trainingFileId;
          result.lessonTotalCount = this.TrainingFileInfo.lessonTotalCount;
          result.lessonWaitCount = this.TrainingFileInfo.lessonWaitCount;
          result.postpone = this.TrainingFileInfo.postpone;
          result.freezeLesson = this.TrainingFileInfo.freezeLesson;
          result.trainingMonth = this.TrainingFileInfo.trainingMonth;
          result.trainingDuration = this.TrainingFileInfo.trainingDuration;
          result.weeklyTrainingDay = this.TrainingFileInfo.weeklyTrainingDay;
        }

        return result;
    },
    SelectedPackageInfo(){
      let result={
        title:'',
        month:0,
        duration:0,
        weekDay:0,
        packageRights:{
          postpone:0,
          freezeLesson:0
        },
      };
      if(this.selectedPackage.packageId){
        let packages = [];
        for(let key in this.packages){
          let obj = this.packages[key];
          for(let prop in obj){
            packages.push(obj[prop]);
          }
        }
        
        let selectedPackage = packages.filter(x=>x.id==this.selectedPackage.packageId);
        
        if(selectedPackage){
          result.title=selectedPackage[0].packageTitle;
          result.month=selectedPackage[0].month;
          result.duration=selectedPackage[0].duration;
          result.weekDay=selectedPackage[0].weekDay;
          result.packageRights.postpone=selectedPackage[0].packageRight.postpone;
          result.packageRights.freezeLesson=selectedPackage[0].packageRight.freezeLesson;
        }
      }
      return result;
    },
  },
  methods:{
    ...mapActions(["MEMBERPROFILE_GETPACKAGES"]),
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    PackageGroupName(value){
      if(value==2){
        return "2 Aylık Paketler"
      }else if(value==4){
        return "4 Aylık Paketler"
      }else if(value==6){
        return "6 Aylık Paketler"
      }else if(value==12){
        return "12 Aylık Paketler"
      }else{
        return "";
      }
    },
    ChangePackage(){
      
      

    },
    GetInitData(){
      this.GetPackages();
      this.Init();
    },
    Init(){
      this.selectedPackage.packageId='';
      this.createPackageLoading=false;

    },
    GetPackages(){
      this.MEMBERPROFILE_GETPACKAGES()
      .then((res)=>{
        this.packages = Object.fromEntries(
            Object.entries(res.data).filter(([key]) => key > 0)
        );
      })
      .catch(error=>{
        if(this.IsShowErrorMessage(error)){
            this.$toast.error(error,{
                position : 'top',
                duration : 3000
            });
          }
      });
    },
    
  },
  watch: {
    Visible(value){
      if(value && this.MemberId){
        this.showTab='show active';
        this.GetInitData();
      }else{
        this.showTab='';
      }
    }
  },
  mounted: function() {

  }
  
}
</script>