<template>
  <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Öğrenci İşlemleri</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{path:'/'}">Anasayfa</router-link>
              </li>
              <li class="breadcrumb-item active">Öğrenci İşlemleri</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
      <div class="container-fluid">        
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-8 col-lg-8">
                      <button class="btn btn-success btn-sm mr-1 float-left" @click="ListButtonClick('active')" :disabled="dataLoading">Aktif Öğrenciler</button>
                      <button class="btn btn-warning btn-sm mr-1 float-left" @click="ListButtonClick('breaktime')" :disabled="dataLoading">Yedek Eğitmende Olan</button>
                      <button class="btn btn-warning btn-sm mr-1 float-left" @click="ListButtonClick('findteacher')" :disabled="dataLoading">Eğitmen Arayan</button>
                      <button class="btn btn-default btn-sm mr-1 float-left" @click="ListButtonClick('freezeeducation')" :disabled="dataLoading">Ders Donduran</button>
                      <button class="btn btn-danger  btn-sm mr-1 float-left" @click="ListButtonClick('inactive')" :disabled="dataLoading">Pasif Öğrenciler</button>
                      <button class="btn btn-warning btn-sm mr-1 float-left" @click="ListButtonClick('complated')" :disabled="dataLoading">Eğitimi Tamamlayan Öğrenciler</button>
                      <button class="btn btn-warning btn-sm mr-1 float-left" @click="ListButtonClick('tryyourself')" :disabled="dataLoading">Kendini Dene</button>
                      <button class="btn btn-warning btn-sm mr-1 float-left" @click="ListButtonClick('demo')" :disabled="dataLoading">Demo</button>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                      <div class="float-right">
                        <div class="input-group input-group-sm">
                          <select class="form-control" v-model="searchTextType" :disabled="dataLoading">
                            <option value="1" selected>Ad Soyad</option>
                            <option value="3">E-Posta</option>
                            <option value="2">Telefon</option>
                          </select>
                          <input type="text" class="form-control" v-model="searchText" :disabled="dataLoading">
                          <span class="input-group-append">
                            <button type="button" class="btn btn-default btn-flat" @click="ListButtonClick('textSearch')" :disabled="dataLoading">Ara</button>
                          </span>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <span v-if="listType==null">Henüz bir liste seçmediniz! Yukarıda bulunan listeleme seçenekleri kullanabilirsiniz.</span>
                <span v-else-if="dataLoading"><i class="fas fa-solid fa-circle-notch fa-spin"></i> Yükleniyor. Lütfen bekleyiniz.</span>
                <span v-else>
                  <h5 class="float-left">{{listTitle}}</h5>
                  <span class="float-right">{{totalItems}} adet kayıt bulundu.</span>
                </span>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                <div class="row p-2 border-bottom" v-for="(item,index) in items" :key="index" >
                  <div class="col-sm-12 col-md-4">
                    <div class="btn-group btn-group-sm float-left mr-2">
                      <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" :disabled="dataLoading">
                        <i class="fas fa-bars"></i>
                      </button>
                      <div class="dropdown-menu" role="menu">
                        <a class="dropdown-item text-sm" href="javascript:;" @click="OpenScheduleModal(item.id)">Ders Programı</a>
                        <a class="dropdown-item text-sm" href="javascript:;" @click="OpenNoteListModal(item.id)">Not Listesi</a>
                        <a class="dropdown-item text-sm" href="javascript:;" @click="OpenCloseMemberTransactionModal(true,item.id)">İşlemler</a>
                        <!-- <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#" @click="OpenPackageModal()">Eğitim Paketi</a> -->
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item text-sm" href="javascript:;" @click="OpenUpdateInfoModal(item.id,item.name,item.surname,item.gender)">Bilgileri Düzelt</a>
                        <a class="dropdown-item text-sm" href="javascript:;" @click="OpenUpdateContactInfoModal(item.id,item.eMail,item.phoneNumber)">İletişim Bilgilerini Düzelt</a>
                        <a class="dropdown-item text-sm" href="javascript:;" @click="OpenUpdateUserModal(item.id,item.memberUsers)">Kullanıcılar</a>
                        <!-- <div class="dropdown-divider"></div>
                        <a class="dropdown-item text-sm" href="javascript:;" @click="OpenCloseTaskModal(true,item.id)">Hatırlatma / Görev Ekle</a> -->
                        <!-- <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#">Yapılan İşlemler</a> -->
                      </div>
                    </div>
                    <h5 class="float-left text-capitalize"><i class="nav-icon fas fa-sm" v-if="item.gender>0" :class="{'fa-mars text-primary':item.gender==1,'fa-venus text-danger':item.gender==2}"></i> <a href="javascript:;" @click="OpenCloseMemberProfileModal(true,item.id)">{{item.name}} {{item.surname}}</a></h5>
                    <span class="badge mr-2 mb-1 ml-2 float-left badge-danger" v-if="item.isDemo">Demo</span>
                    <div class="clearfix"></div>
                    <span class="mr-1 text-sm" v-html="item.id"></span>
                    <div class="clearfix"></div>
                    <span class="badge mr-1" :class="{'badge-danger':item.studentStatus==0,'badge-success':item.studentStatus==1}">{{StudentStatus(item.studentStatus)}}</span>
                    <span class="p-1 mr-1 pointer text-sm" @click="OpenSMSModal(item)"><i class="nav-icon fas fa-phone fa-sm"></i> {{item.phoneNumber}}</span>
                    <a class="ml-2" v-bind:href="'https://wa.me/' + item.phoneNumber.replace('0090-','90')" target="_blank"><i class="fab fa-whatsapp"></i></a>&nbsp;|&nbsp;
                    <span class="p-1 pointer text-sm" @click="OpenMailModal(item)"><i class="nav-icon fas fa-at fa-sm"></i> {{item.eMail}}</span>
                    <div class="clearfix"></div>
                    
                  </div>
                  
                  <div class="col-sm-12 col-md-3">
                    <div>
                      <span class="badge mr-2 mb-1 float-left badge-danger" v-if="item.trainingFile.isDemo">Demo</span>
                      <span class="badge mr-2 mb-1 float-left badge-info" v-if="item.trainingFile.isTryYourself">Kendini Dene</span>
                      <div class="clearfix"></div>
                      <div class="dropdown mb-2">
                        <button class="btn btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :class="{'btn-success':item.trainingFile.status==1,'btn-default':item.trainingFile.status==2,'btn-warning':item.trainingFile.status>2}">
                        <span class="h5">{{CalculateTimeByOffset(item.trainingFile.lessonTime)}}</span> {{item.trainingFile.teacherName}}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <a class="dropdown-item text-sm" href="javascript:;" @click="OpenAddRemoveLessonModal(item.id,item.trainingFile.id)">Ders(ler) Ekle / Sil</a>
                          <a class="dropdown-item text-sm" href="javascript:;" @click="OpenChangeTeacherModal(item.id,item.trainingFile.id,item.trainingFile.lessonTime,item.trainingFile.duration,item.trainingFile.teacherId,item.trainingFile.weekDay)">Eğitmen ve Saat Değişikliği</a>
                          <a class="dropdown-item text-sm" href="javascript:;" @click="OpenCloseFreezeTrainingModal(true,item.id,item.trainingFile.id)">Dersleri Dondur</a>
                          <a class="dropdown-item text-sm" href="javascript:;" @click="OpenCloseLessonSlideModal(true,item.id,item.trainingFile.id)">Dersleri Kaydır</a>
                          <a class="dropdown-item text-sm" href="javascript:;" @click="OpenCloseUpdateStartDateModal(true,item.id,item.trainingFile.id)">Başlangıç Tarihini Değiştir</a>
                        </div>
                      </div>
                    </div>
                    <div class="text-sm pointer" @click="OpenUpdateContactModal(item.id,item.trainingFile.id)">
                        <i class="fas fa-phone-alt" v-if="item.trainingFile.contactType===1"></i> 
                        <i class="far fa-envelope" v-else-if="item.trainingFile.contactType===2"></i> 
                        <i class="fab fa-skype" v-else-if="item.trainingFile.contactType===3"></i> 
                        {{item.trainingFile.contactData}}
                    </div>
                    <div class="mt-2 text-sm" v-tooltip="'Eğitim Paketi'">
                      <span class="mr-2"><i class="far fa-calendar-alt"></i> {{item.trainingFile.month}} Ay (Haftada {{item.trainingFile.weekDay}} Gün)</span>
                      <span><i class="fas fa-stopwatch"></i> {{item.trainingFile.duration}} Dakika</span>
                      <span class="badge ml-2 mt-1" :class="{'badge-success':item.trainingFile.status==1,'badge-default':item.trainingFile.status==2,'badge-warning':item.trainingFile.status>2}">{{TrainingFileStatus(item.trainingFile.status)}}</span>
                    </div>
                    <div class="mt-2 text-sm">
                        <span class="mr-2 pointer" v-tooltip="'Eğitmen Saat Değişikliği'" @click="OpenUpdateRightModal(item.id,item.trainingFile.id,1)"><i class="fas fa-people-arrows"></i> {{item.trainingFile.rights.teacherChange}} Adet</span>
                        <span class="mr-2 pointer" v-tooltip="'Ders Erteleme'" @click="OpenUpdateRightModal(item.id,item.trainingFile.id,2)"><i class="fas fa-redo-alt"></i> {{item.trainingFile.rights.postpone}} Adet</span>
                        <span class="pointer" v-tooltip="'Ders Dondurma'" @click="OpenUpdateRightModal(item.id,item.trainingFile.id,4)"><i class="fas fa-snowflake"></i> {{item.trainingFile.rights.freezeLesson}} Gün</span>
                    </div>
                  </div>
                  
                  <div class="col-sm-12 col-md-3">
                      <ul class="pagination pagination-month pagination-sm justify-content-center" v-tooltip="'Son 5 dersin durumu'">
                        <li class="page-item"><a class="page-link" href="javascript:;" @click="GetLessonsSummary(item.id,item.lastLessons,index,0)">«</a></li>
                        <li class="page-item" v-for="lesson in item.lastLessons" :key="lesson.date">
                            <a class="page-link" href="javascript:;" @click="OpenViewScheduleByDay(item.name,item.surname,lesson.date,item.id,lesson.trainingFileId)">
                                <p class="page-month" v-if="lesson.status===0">-</p>
                                <p class="page-month text-danger" v-else-if="lesson.status===1"><i class="fas fa-times"></i></p>
                                <p class="page-month" v-else-if="lesson.status===2"><i class="fas fa-check"></i></p>
                                <p class="page-month text-warning" v-else-if="lesson.status===3"><i class="fas fa-redo-alt"></i></p>
                                <p class="page-month text-danger" v-else-if="lesson.status===4"><i class="fas fa-times"></i></p>
                                <p class="page-year text-muted">{{ScheduleDateFormat(lesson.date)}}</p>
                            </a>
                        </li>
                        <li class="page-item"><a class="page-link" href="javascript:;" @click="GetLessonsSummary(item.id,item.lastLessons,index,1)">»</a></li>
                      </ul>
                        <div class="mt-2">
                          <div class="row border border-1">
                            <div class="col-4 text-bold">Toplam:</div>
                            <div class="col-2">{{item.trainingFile.lessonTotalCount}}</div>
                            <div class="col-4 text-bold">Kalan:</div>
                            <div class="col-2">{{item.trainingFile.lessonWaitCount}}</div>
                          </div>
                          <div class="row border border-1">
                            <div class="col-4 text-bold text-success">İşlenen:</div>
                            <div class="col-2">{{item.trainingFile.lessonComplatedCount}}</div>
                            <div class="col-4 text-bold text-danger">İşlenmeyen:</div>
                            <div class="col-2">{{item.trainingFile.lessonTotalCount-(item.trainingFile.lessonComplatedCount+item.trainingFile.lessonWaitCount)}}</div>
                          </div>
                        </div>
                  </div>
                  <div class="col-sm-12 col-md-2">
                    <div class="role bg-info float-left text-center text-sm  rounded mb-1 mr-1 p-2" v-for="user in item.memberUsers" :key="user"  v-show="user.fullName!=null && user.fullName.length>2" v-tooltip="Role(user.role)" >{{user.fullName}}</div>
                  </div>
                  
                </div>
              </div>
              <!-- /.card-body -->
              <!-- /.card -->
              <div class="card-footer clearfix" v-show="totalPages>0">
                <PaginationBST :page="currentPage" :totalPage="totalPages" @PageNumber="GetMemberStudentList($event)"></PaginationBST>
              </div>
            </div>
          </div>
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->
    
    <!-- Modal UpdateRightModal -->
    <div class="modal fade" id="UpdateRightModal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Eğitim Haklarını Güncelleme</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
                
            <div class="modal-body">
              <div class="form-group">
                <Form @submit="SaveRightForm" :validation-schema="schemaUpdateRightForm" ref="UpdateRightForm">
                  <div class="input-group">
                    <Field type="text" class="form-control" id="RightNumber" name="RightNumber"/>
                    <div class="input-group-append">
                      <button type="submit" class="btn btn-primary" :disabled="modalSubmitLoading">
                        <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                        <span class="ml-2">Kaydet</span>
                      </button>
                    </div>
                  </div>
                  <small><ErrorMessage name="RightNumber" class="error-feedback"/></small>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>

    <!-- Modal UpdateContactInfoModal -->
    <div class="modal fade" id="UpdateContactModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Skype Adresi Güncelleme</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
              
          <div class="modal-body">
            <div class="form-group">
              <Form @submit="SaveSkypeAddressForm" :validation-schema="schemaSkypeAddressForm" ref="SkypeAddressForm">
                <div class="input-group">
                  <Field type="text" class="form-control" id="SkypeAddressSkype" name="SkypeAddressSkype"/>
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-primary" :disabled="modalSubmitLoading">
                      <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                      <span class="ml-2">Kaydet</span>
                    </button>
                  </div>
                </div>
                <small><ErrorMessage name="SkypeAddressSkype" class="error-feedback"/></small>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal NotifcationSMSModal -->
    <div class="modal fade" id="NotifcationSmsModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">SMS Gönder</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <input class="form-control" v-model="smsSendData.phoneNumber" disabled>
                    </div>
                    <div class="form-group row">
                      <select class="form-control" v-model="smsSendData.id">
                          <option value=""></option>
                          <option value="99999">Sms İçeriği Oluştur</option>
                          <option v-for="t in smsTemplates" :key="t" v-bind:value="t.id">{{t.title}}</option>
                        </select>
                    </div>
                    <div class="form-group row">
                        <textarea class="form-control" style="resize: none;" rows="5" v-model="smsSendData.content" :disabled="smsSendData.id!=99999"></textarea>
                    </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary" :disabled="smsSendData.formSendLoading || smsSendData.id==0" @click="SendSms()">
                <span class="spinner-border spinner-border-sm" v-show="smsSendData.formSendLoading"></span>
                <span class="ml-2">Gönder</span>
              </button>
            </div>
        </div>
      </div>
    </div>

    <!-- Modal NotifcationMailModal -->
    <div class="modal fade" id="NotifcationMailModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">E-Posta Gönder</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <input class="form-control" v-model="mailSendData.to" disabled>
                    </div>
                    <div class="form-group row">
                        <select class="form-control" v-model="mailSendData.id">
                          <option value="0"></option>
                          <option value="99999">Mail İçeriği Oluştur</option>
                          <option v-for="t in mailTemplates" :key="t" v-bind:value="t.id">{{t.title}}</option>
                        </select>
                    </div>
                    <div class="form-group row">
                      <label>Mail Konusu</label>  
                      <input class="form-control" v-model="mailSendData.subject">
                    </div>
                    <div v-if="mailSendData.id==99999">
                      <editor :init="{license_key: 'gpl',menubar: false,toolbar: 'bold italic | alignleft aligncenter alignright alignjustify | bullist numlist'}" v-model="mailSendData.content"  tinymceScriptSrc="/tinymce/tinymce.min.js"/>
                    </div>
                    <div class="form-group row m-0" v-else-if="mailSendData.id>0">
                      <iframe id="MailView" v-bind:srcdoc="mailSendData.content" width="100%" height="350" frameborder="0" scrolling="yes"></iframe>
                    </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary" :disabled="mailSendData.formSendLoading || mailSendData.id==0" @click="SendMail()">
                <span class="spinner-border spinner-border-sm" v-show="mailSendData.formSendLoading"></span>
                <span class="ml-2">Gönder</span>
              </button>
            </div>
        </div>
      </div>
    </div>

    <!-- Modal NoteListModal -->
    <div class="modal fade" id="NoteListModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Not Listesi</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-4">
                    <Form @submit="SaveNoteMemberForm" :validation-schema="schemaNoteMemberForm" ref="NoteMemberForm">
                    <div class="form-group row">
                        <Field class="form-control" style="resize: none;" rows="15" name="NoteMemberFormContent" id="NoteMemberFormContent" as="textarea"/>
                        <small><ErrorMessage name="NoteMemberFormContent" class="error-feedback"/></small>
                    </div>
                    <div class="form-group row">
                      <button type="submit" class="btn btn-primary" :disabled="modalSubmitLoading">
                        <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                        <span class="ml-2">Kaydet</span>
                      </button>
                    </div>
                    </Form>
                </div>
                <div class="col-8">
                  <div class="overflow-auto mb-1" style="height:400px;">
                    <div class="border-bottom p-1 mb-1" v-for="item in note.items" :key="item.id">
                      <small>
                        <span class="text-bold"><em>{{item.creationUserFullName}} | {{DateFormat(item.creationDate)}}</em></span>
                        <p>{{item.description}}</p>
                      </small>
                    </div>
                  </div>
                  <div v-show="note.totalPages>0">
                    <ul class="pagination pagination-sm m-0 float-right">
                      <li class="page-item" v-show="(note.currentPage-1)>0"><button class="page-link" @click="GetMemberNote(note.currentPage-1)" :disabled="note.dataLoading">&laquo;</button></li>
                      <li v-for="page in note.totalPages" :key="page" class="page-item" :class="{'active':page==note.currentPage}"><button class="page-link" @click="GetMemberNote(page)" :disabled="note.dataLoading">{{page}}</button></li>
                      <li class="page-item" v-show="(note.currentPage+1)<=note.totalPages"><button class="page-link" @click="GetMemberNote(note.currentPage+1)" :disabled="note.dataLoading">&raquo;</button></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>

    <!-- Modal UpdateInfoModal -->
    <div class="modal fade" id="UpdateInfoModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Bilgileri Düzelt</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <Form @submit="SaveInfoMemberForm" :validation-schema="schemaInfoMemberForm" ref="InfoMemberForm">
              <div class="modal-body">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label" for="InfoMemberFormName">Ad</label>
                  <div class="col-sm-9">
                    <Field type="text" class="form-control text-capitalize" id="InfoMemberFormName" name="InfoMemberFormName"/>
                    <small><ErrorMessage name="InfoMemberFormName" class="error-feedback"/></small>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label" for="InfoMemberFormSurname">Soyad</label>
                  <div class="col-sm-9">
                    <Field type="text" class="form-control text-capitalize" id="InfoMemberFormSurname" name="InfoMemberFormSurname"/>
                    <small><ErrorMessage name="InfoMemberFormSurname" class="error-feedback"/></small>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label" for="gender">Cinsiyet</label>
                  <div class="col-sm-9">
                    <Field class="form-control" name="InfoMemberFormGenderOption" id="InfoMemberFormGenderOption" as="select">
                      <option value=""></option>
                      <option value="1">Erkek</option>
                      <option value="2">Kadın</option>
                    </Field>
                    <small><ErrorMessage name="InfoMemberFormGenderOption" class="error-feedback"/></small>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-primary" :disabled="modalSubmitLoading">
                  <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                  <span class="ml-2">Kaydet</span>
                </button>
              </div>
              <Field type="hidden" name="InfoMemberFormId" id="InfoMemberFormId"/>
          </Form>
        </div>
      </div>
    </div>

    <!-- Modal UpdateContactInfoModal -->
    <div class="modal fade" id="UpdateContactInfoModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">İletişim Bilgileri Düzelt</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
              
          <div class="modal-body">
            <div class="form-group">
              <Form @submit="SaveContactEmailMemberForm" :validation-schema="schemaContactEmailMemberForm" ref="ContactEmailMemberForm">
                <div class="input-group">
                  <Field type="text" class="form-control" id="ContactEmailMemberFormEmail" name="ContactEmailMemberFormEmail"/>
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-primary" :disabled="modalSubmitLoading">
                      <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                      <span class="ml-2">Kaydet</span>
                    </button>
                  </div>
                </div>
                <small><ErrorMessage name="ContactEmailMemberFormEmail" class="error-feedback"/></small>
                <Field type="hidden" name="ContactEmailMemberFormId" id="ContactEmailMemberFormId"/>
              </Form>
            </div>
            <div class="form-group">
              <Form @submit="SaveContactPhoneMemberForm" :validation-schema="schemaContactPhoneMemberForm" ref="ContactPhoneMemberForm">
                <div class="input-group">
                  <Field type="text" class="form-control col-2" id="ContactPhoneMemberFormPhoneCode" name="ContactPhoneMemberFormPhoneCode"/>
                  <Field type="text" class="form-control" id="ContactPhoneMemberFormPhone" name="ContactPhoneMemberFormPhone"/>
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-primary" :disabled="modalSubmitLoading">
                      <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                      <span class="ml-2">Kaydet</span>
                    </button>
                  </div>
                </div>
                <small><ErrorMessage name="ContactPhoneMemberFormPhone" class="error-feedback"/></small>
                <Field type="hidden" name="ContactPhoneMemberFormId" id="ContactPhoneMemberFormId"/>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal UpdateUserModal -->
    <div class="modal fade" id="UpdateUserModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Kullanıcılar</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <div class="modal-body">
                <div class="form-group">
                  <Form @submit="SaveUserFormOperatorMemberForm" :validation-schema="schemaUserFormOperatorMemberForm" ref="UserFormOperatorMemberForm">
                    <label class="col-form-label" for="UserFormOperatorOption">Form Temsilcisi</label>
                    <div class="input-group">
                      <Field class="form-control" name="UserFormOperatorOption" id="UserFormOperatorOption" as="select">
                        <option value=""></option>
                        <option v-for="user in operationUsers.filter(x=>x.role===3)" :key="user" :value="user.id">{{user.fullName}}</option>
                      </Field>
                      <div class="input-group-append">
                        <button type="submit" class="btn btn-primary" :disabled="modalSubmitLoading">
                          <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                          <span class="ml-2">Kaydet</span>
                        </button>
                      </div>
                    </div>
                    <small><ErrorMessage name="UserFormOperatorOption" class="error-feedback"/></small>
                    <Field type="hidden" name="UserFormOperatorMemberFormId" id="UserFormOperatorMemberFormId"/>
                  </Form>
                </div>
                <hr/>
                <div class="form-group">
                  <Form @submit="SaveUserSaleMemberForm" :validation-schema="schemaUserSaleMemberForm" ref="UserSaleMemberForm">
                    <label class="col-form-label" for="UserSaleOption">Satış Temsilcisi</label>
                    <div class="input-group">
                      <Field class="form-control" name="UserSaleOption" id="UserSaleOption" as="select">
                        <option value=""></option>
                        <option v-for="user in operationUsers.filter(x=>x.role===4)" :key="user" :value="user.id">{{user.fullName}}</option>
                      </Field>
                      <div class="input-group-append">
                        <button type="submit" class="btn btn-primary" :disabled="modalSubmitLoading">
                          <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                          <span class="ml-2">Kaydet</span>
                        </button>
                      </div>
                    </div>
                    <small><ErrorMessage name="UserSaleOption" class="error-feedback"/></small>
                    <Field type="hidden" name="UserSaleMemberFormId" id="UserSaleMemberFormId"/>
                  </Form>
                </div>
                <hr/>
                <div class="form-group">
                  <Form @submit="SaveUserStudentOperatorMemberForm" :validation-schema="schemaUserStudentOperatorMemberForm" ref="UserStudentOperatorMemberForm">
                    <label class="col-form-label" for="UserStudentOperatorOption">Öğrenci Temsilcisi</label>
                    <div class="input-group">
                      <Field class="form-control" name="UserStudentOperatorOption" id="UserStudentOperatorOption" as="select">
                        <option value=""></option>
                        <option v-for="user in operationUsers.filter(x=>x.role===2)" :key="user" :value="user.id">{{user.fullName}}</option>
                      </Field>
                      <div class="input-group-append">
                        <button type="submit" class="btn btn-primary" :disabled="modalSubmitLoading">
                          <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                          <span class="ml-2">Kaydet</span>
                        </button>
                      </div>
                    </div>
                    <small><ErrorMessage name="UserStudentOperatorOption" class="error-feedback"/></small>
                    <Field type="hidden" name="UserStudentOperatorMemberFormId" id="UserStudentOperatorMemberFormId"/>
                  </Form>
                </div>
            </div>
        </div>
      </div>
    </div>

    <!-- Modal ScheduleModal -->
    <div class="modal fade" id="ScheduleModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Ders Programı</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <div class="modal-body">
                <div class="row">
                  <div class="col-2"><a href="#" class="btn btn-default" @click="onClickPrevMonth()"><i class="fas fa-angle-double-left"></i> Geri</a></div>
                  <div class="col-8 text-center"><h3>{{GetCalenderMonth}} / {{GetCalenderYear}}</h3></div>
                  <div class="col-2 text-right"><a href="#" class="btn btn-default" @click="onClickNextMonth()">İleri <i class="fas fa-angle-double-right"></i></a></div>
                </div>
                <div class="row">
                  <div class="col-12 text-sm">Ders programında <strong>{{scheduleCalender.nextLessonCount}}</strong> adet işlenmemiş ders bulunmaktadır.</div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="table-responsive" style="height: 400px;">
                      <a name="begincalender"></a>
                      <table class="table table-sm table-head-fixed text-nowrap">
                        <thead>
                          <tr>
                            <th style="width: 30px" class="text-center">Tarih</th>
                            <th style="width: 30px" class="text-center">Durum</th>
                            <th style="width: 120px" class="text-center">Seviye</th>
                            <th >Konu</th>
                            <th style="width: 77px">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="day in scheduleCalender.calender" :key="day" :class="{'bg-maroon':day.status==1,'bg-olive':day.status==2,'badge-warning':day.status==3}">
                            <td class="text-center">
                              <a v-bind:name="'day'+day.dayNumber"></a>
                              <h3 class="mb-0">{{day.dayNumber}}</h3><small>{{day.dayText}}</small>
                            </td>
                            <td class="text-center" v-tooltip="day.statusText">
                              <h3 class="mt-2">
                                <i class="fas fa-minus" v-if="day.status==0"></i>
                                <i class="fas fa-times" v-else-if="day.status==1"></i>
                                <i class="fas fa-check" v-else-if="day.status==2"></i>
                                <i class="fas fa-redo-alt" v-else-if="day.status==3"></i>
                                <i class="fas fa-redo-alt" v-else-if="day.status==5"></i>
                              </h3>
                            </td>
                            <td>
                              <span v-if="day.status==3 || day.status==5 || day.status==6"></span>
                              <span v-else>{{day.level}}</span>
                              </td>
                            <td class="align-middle">
                                <div v-if="day.status==3 || day.status==5">
                                  Ders Ertelendi
                                </div>
                                <div v-else-if="day.status==6">
                                  Ders Ertelenedi
                                </div>
                                <div v-else>
                                  <div>
                                    <span class="badge bg-primary" style="font-weigth:normal;">{{day.bookName}}</span>
                                  </div>
                                  {{day.bookSubject}}
                                </div>
                              </td>
                            <td class="text-right align-middle">
                              <div class="btn-group"  v-show="day.bookId!=''">
                                <button type="button" class="btn btn-default btn-sm" v-show="day.status==0 || day.status==2" @click="OpenLessonResultModal(day.dayNumber,day.trainingFileId)">
                                  <i class="fas fa-eye"></i>
                                </button>
                                <button type="button" class="btn btn-default btn-sm"  v-if="IsPermision(PermissionCodes.STUDENT18)" v-show="day.status==0" @click="handlerOpenCloseBookChangeUnitModal(true,{dayNumber:day.dayNumber,bookName:day.bookName,bookLevel:day.level,bookSubject:day.bookSubject,trainingFileId:day.trainingFileId,bookId:day.bookId})">
                                  <i class="fas fa-exchange-alt"></i>
                                </button>
                                <!-- <button type="button" class="btn btn-default btn-sm" v-show="day.status==0" @click="OpenChangeUnitModal(day.dayNumber,day.bookName,day.level,day.bookSubject,day.trainingFileId)">
                                  <i class="fas fa-exchange-alt"></i>
                                </button> -->
                              </div>
                            </td>
                          </tr> 
                          
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </div>

    <!-- Modal ChangeUnitModal -->
    <!-- <div class="modal fade" id="ChangeUnitModal" tabindex="1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Ünite Değiştir</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Form @submit="SaveChangeUnitForm" :validation-schema="schemaChangeUnitForm" ref="ChangeUnitForm">
          <div class="modal-body">
            <div class="row mb-2">
              <div class="col-5">
                <strong>Tarih : </strong>{{ScheduleFindDateFormat(changeUnit.selectedDate)}}<br>
                <strong>Seviye :</strong> {{changeUnit.selectedLevel}}
              </div>
              <div class="col-7">
                <strong>Kitap :</strong> {{changeUnit.selectedBook}}<br>
                <strong>Konu : </strong> {{changeUnit.selectedSubject}}
                </div>
            </div>
            <div class="form-group">
                <Field class="form-control" name="NewBookSubject" id="NewBookSubject" as="select">
                  <optgroup v-for="b in changeUnit.bookSubjectList" :key="b" :label="b.book.title">
                      <option v-for="(s,i) in b.subjects" :key="s" :value="b.book.bookNameId+'#'+s.id">
                        {{(i+1) + ') ' + s.levelTitle + ' - ' + s.subject }}
                      </option>
                  </optgroup>
                </Field>
                <small><ErrorMessage name="NewBookSubject" class="error-feedback"/></small>
            </div>
            <div class="form-check">
              <Field type="radio" class="form-check-input" name="ChangeUnitType" id="ChangeUnitTypeOneLesson" value="ChangeOneLesson" />
              <label class="form-check-label" for="ChangeUnitTypeOneLesson">Sadece {{ScheduleFindDateFormat(changeUnit.selectedDate)}} tarihli dersin konusunu değiştir.</label>
            </div>
            <div class="form-check">
              <Field type="radio" class="form-check-input" name="ChangeUnitType" id="ChangeUnitTypeAllLesson" value="ChangeAllLesson"/>
              <label class="form-check-label" for="ChangeUnitTypeAllLesson">{{ScheduleFindDateFormat(changeUnit.selectedDate)}} ve sonrası derslerin konularını değiştir.</label>
            </div>
            <small><ErrorMessage name="ChangeUnitType" class="error-feedback"/></small>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary" :disabled="modalSubmitLoading">
              <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
              <span class="ml-2">Güncelle</span>
            </button>
          </div>
          </Form>
        </div>
      </div>
    </div> -->

    <!-- Modal LessonResultModal -->
    <div class="modal fade" id="LessonResultModal" tabindex="1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">{{ScheduleFindDateFormat(lessonResult.date)}} - {{lessonResult.book.subject}} </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-2">
              <div class="col-12">
                <div class="row mb-3">
                  <div class="col-12">
                    <div class="btn-group">
                        <!-- <button type="button" class="btn btn-info" disabled>Pre-Intermediate</button> -->
                        <a class="btn btn-default" target="_blank" v-bind:href="GetMainContentInLessonResult" v-show="GetMainContentInLessonResult!=null" ><i class="fas fa-book-open"></i> Kitap-1</a>
                        <a class="btn btn-default" target="_blank" v-bind:href="GetContent2InLessonResult" v-show="GetContent2InLessonResult!=null"><i class="fas fa-book-open"></i> Kitap-2</a>
                        <a class="btn btn-default" target="_blank" v-bind:href="GetExerciseInLessonResult" v-show="GetExerciseInLessonResult!=null"><i class="fas fa-question-circle"></i> Alıştırma</a>
                        <a class="btn btn-default" target="_blank" v-bind:href="GetVideoInLessonResult" v-show="GetVideoInLessonResult!=null"><i class="fas fa-video"></i> Video</a>
                      </div>
                  </div>
                </div>
                <div id="accordion">
                  <div class="card card-secondary">
                    <div class="card-header">
                      <h4 class="card-title w-100">
                        <a class="d-block w-100" data-toggle="collapse" href="#words">
                          <i class="fas fa-angle-double-right"></i> Öğrenilmesi Gereken Kelimeler
                        </a>
                      </h4>
                    </div>
                    <div id="words" class="collapse show" data-parent="#accordion">
                      <div class="card-body">
                        <button type="button" class="btn btn-sm btn-outline-primary mb-1 mr-1" v-for="word in lessonResult.book.words" :key="word">{{word.word}}</button>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="card card-secondary">
                    <div class="card-header">
                      <h4 class="card-title w-100">
                        <a class="d-block w-100" data-toggle="collapse" href="#studentcomment">
                          <i class="fas fa-angle-double-right"></i> Öğrenci Değerlendirmesi
                        </a>
                      </h4>
                    </div>
                    <div id="studentcomment" class="collapse" data-parent="#accordion">
                      <div class="card-body">
                        <div><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i> (5/5)</div>
                        Güzel bir dersti. Çok şey kattı bana
                      </div>
                    </div>
                  </div> -->
                  <div class="card card-secondary" v-if="lessonResult.result!=null">
                    <div class="card-header">
                      <h4 class="card-title w-100">
                        <a class="d-block w-100" data-toggle="collapse" href="#teachercomment">
                          <i class="fas fa-angle-double-right"></i> Öğretmen Değerlendirmesi
                        </a>
                      </h4>
                    </div>
                    <div id="teachercomment" class="collapse" data-parent="#accordion">
                      <div class="card-body">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>Dinleme</th>
                              <th>Okuma</th>
                              <th>Konuşma</th>
                              <th>Kelime B.</th>
                              <th>Dilbilgisi</th>
                            </tr>
                            </thead>
                            <tbody>
                              <tr>
                              <td class="text-center h3">{{LessonPuan(lessonResult.result.listening)}}</td>
                              <td class="text-center h3">{{LessonPuan(lessonResult.result.reading)}}</td>
                              <td class="text-center h3">{{LessonPuan(lessonResult.result.speaking)}}</td>
                              <td class="text-center h3">{{LessonPuan(lessonResult.result.vocabulary)}}</td>
                              <td class="text-center h3">{{LessonPuan(lessonResult.result.grammar)}}</td>
                            </tr>
                            </tbody>
                          </table>
                          <hr class="my-4">
                        <!-- <div><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i> (3/5)</div> -->
                        <div v-html="lessonResult.result.note"></div>
                        <!-- <p><del>My name enis</del> My name is Enis</p>
                        <p><del>How old you?</del> How old are you?</p> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal PackageModal -->
    <div class="modal fade" id="PackageModal" tabindex="1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="progress mb-3">
              <div class="progress-bar bg-private" role="progressbar" aria-valuemin="0" aria-valuemax="100" v-bind:style="{width: stepper.progressValue + '%'}"></div>
            </div>
            <div class="pr-3 pl-3 pb-3" v-show="stepper.stepBlock1">
                <h3>Paket Seçimi</h3>
                <div class="row mb-2">
                    <div class="col-7">
                      <div class="form-group">
                        <select class="form-control">
                          <optgroup label="3 AYLIK PAKETLER">
                            <optgroup label="5 gün">
                              <option>3 Ay 10 dk (Haftada 5 gün)</option>
                              <option>3 Ay 20 dk (Haftada 5 gün)</option>
                              <option>3 Ay 30 dk (Haftada 5 gün)</option>
                              <option>3 Ay 40 dk (Haftada 5 gün)</option>
                              <option>3 Ay 50 dk (Haftada 5 gün)</option>
                              <option>3 Ay 60 dk (Haftada 5 gün)</option>
                            </optgroup>
                            <optgroup label="3 gün">
                              <option>3 Ay 10 dk (Haftada 3 gün)</option>
                              <option>3 Ay 20 dk (Haftada 3 gün)</option>
                              <option>3 Ay 30 dk (Haftada 3 gün)</option>
                              <option>3 Ay 40 dk (Haftada 3 gün)</option>
                              <option>3 Ay 50 dk (Haftada 3 gün)</option>
                              <option>3 Ay 60 dk (Haftada 3 gün)</option>
                            </optgroup>
                            <optgroup label="2 gün">
                              <option>3 Ay 10 dk (Haftada 2 gün)</option>
                              <option>3 Ay 20 dk (Haftada 2 gün)</option>
                              <option>3 Ay 30 dk (Haftada 2 gün)</option>
                              <option>3 Ay 40 dk (Haftada 2 gün)</option>
                              <option>3 Ay 50 dk (Haftada 2 gün)</option>
                              <option>3 Ay 60 dk (Haftada 2 gün)</option>
                            </optgroup>
                          </optgroup>
                          <optgroup label="6 Aylık Paketler">
                            <option>6 Ay 10 dk (Haftada 5 gün)</option>
                            <option>6 Ay 20 dk (Haftada 5 gün)</option>
                            <option>6 Ay 30 dk (Haftada 5 gün)</option>
                            <option>6 Ay 40 dk (Haftada 5 gün)</option>
                            <option>6 Ay 50 dk (Haftada 5 gün)</option>
                            <option>6 Ay 60 dk (Haftada 5 gün)</option>
                            <option>6 Ay 10 dk (Haftada 3 gün)</option>
                            <option>6 Ay 20 dk (Haftada 3 gün)</option>
                            <option>6 Ay 30 dk (Haftada 3 gün)</option>
                            <option>6 Ay 40 dk (Haftada 3 gün)</option>
                            <option>6 Ay 50 dk (Haftada 3 gün)</option>
                            <option>6 Ay 60 dk (Haftada 3 gün)</option>
                            <option>6 Ay 10 dk (Haftada 2 gün)</option>
                            <option>6 Ay 20 dk (Haftada 2 gün)</option>
                            <option>6 Ay 30 dk (Haftada 2 gün)</option>
                            <option>6 Ay 40 dk (Haftada 2 gün)</option>
                            <option>6 Ay 50 dk (Haftada 2 gün)</option>
                            <option>6 Ay 60 dk (Haftada 2 gün)</option>
                          </optgroup>
                        </select>
                      </div>
                      <div class="overflow-auto mt-2" style="height:114px">
                        <div class="form-group">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox">
                            <label class="form-check-label">2022 Yaz Kampanyası (%25)</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox">
                            <label class="form-check-label">Öğrenci İndirimi (%10)</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox">
                            <label class="form-check-label">Genel İndirimi (%5)</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox">
                            <label class="form-check-label">2022 Yaz Kampanyası (%25)</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox">
                            <label class="form-check-label">Öğrenci İndirimi (%10)</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox">
                            <label class="form-check-label">Genel İndirimi (%5)</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox">
                            <label class="form-check-label">2022 Yaz Kampanyası (%25)</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox">
                            <label class="form-check-label">Öğrenci İndirimi (%10)</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox">
                            <label class="form-check-label">Genel İndirimi (%5)</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox">
                            <label class="form-check-label">2022 Yaz Kampanyası (%25)</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox">
                            <label class="form-check-label">Öğrenci İndirimi (%10)</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox">
                            <label class="form-check-label">Genel İndirimi (%5)</label>
                          </div>
                        </div>
                      </div>
                      <div class="mt-2">
                          <div class="row border-bottom">
                            <div class="col-2"></div>
                            <div class="col-2 text-center" v-tooltip="'Eğitim Süresi (Ay)'"><small><i class="far fa-calendar-alt"></i></small></div>
                            <div class="col-2 text-center" v-tooltip="'Ders Erteleme (Adet)'"><small><i class="fas fa-redo-alt"></i></small></div>
                            <div class="col-2 text-center" v-tooltip="'Eğitmen Saat Değişikliği (Adet)'"><small><i class="fas fa-people-arrows"></i></small></div>
                            <div class="col-2 text-center" v-tooltip="'Ders Dondurma (Gün)'"><small><i class="fas fa-snowflake"></i></small></div>
                            <div class="col-2 text-center"><small></small></div>
                          </div>
                          <div class="row border-bottom">
                            <div class="col-2"><small>Paket</small></div>
                            <div class="col-2 text-center"><small>7</small></div>
                            <div class="col-2 text-center"><small>1</small></div>
                            <div class="col-2 text-center"><small>3</small></div>
                            <div class="col-2 text-center"><small>5</small></div>
                            <div class="col-2 text-center"><small></small></div>
                          </div>
                          <div class="row border-bottom">
                            <div class="col-2"><small>Yeni Paket</small></div>
                            <div class="col-2 text-center"><small>6</small></div>
                            <div class="col-2 text-center"><small>25</small></div>
                            <div class="col-2 text-center"><small>55</small></div>
                            <div class="col-2 text-center"><small>4</small></div>
                            <div class="col-2 text-center"><small></small></div>
                          </div>
                          <div class="row border-bottom">
                            <div class="col-2"><small>Kampanya</small></div>
                            <div class="col-2 text-center"><small>1</small></div>
                            <div class="col-2 text-center"><small>5</small></div>
                            <div class="col-2 text-center"><small>0</small></div>
                            <div class="col-2 text-center"><small>5</small></div>
                            <div class="col-2 text-center"><small></small></div>
                          </div>
                          <!-- <span class="mr-2" v-tooltip="'Eğitmen Saat Değişikliği'"><i class="fas fa-people-arrows"></i> 5 Adet</span>
                          <span class="mr-2" v-tooltip="'Ders Erteleme'"><i class="fas fa-redo-alt"></i> 10 Adet</span>
                          <span v-tooltip="'Ders Dondurma'"><i class="fas fa-snowflake"></i> 45 Gün</span> -->
                      </div>
                    </div>
                    <div class="col-5">
                      <div class="form-group"><b>Mevcut Paket :</b><br>7 Ay 10 Dakika (Haftada 5 Gün)</div>
                      <div>
                        <div class="form-group input-group-sm row mb-1">
                          <label class="col-sm-5 col-form-label">Tutar</label>
                          <div class="col-sm-7">
                            <div class="input-group input-group-sm">
                              <input type="text" class="form-control" value="1500.00" disabled>
                              <div class="input-group-append">
                                <span class="input-group-text">TL</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group input-group-sm row mb-1">
                          <label class="col-sm-5 col-form-label">İndirim</label>
                          <div class="col-sm-7">
                            <div class="input-group input-group-sm">
                              <input type="text" class="form-control" value="250.00" disabled>
                              <div class="input-group-append">
                                <span class="input-group-text">TL</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group input-group-sm row mb-1">
                          <label class="col-sm-5 col-form-label">Toplam Tutar</label>
                          <div class="col-sm-7">
                            <div class="input-group input-group-sm">
                              <input type="text" class="form-control" value="1250.00" disabled>
                              <div class="input-group-append">
                                <span class="input-group-text">TL</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="row border-bottom">
                            <div class="col-1"><input type="radio"></div>
                            <div class="col-3"><small>Tek Çekim</small></div>
                            <div class="col-3 text-center"><small>0,00 TL</small></div>
                            <div class="col-5 text-center"><small>1.250,00 TL</small></div>
                          </div>
                          <div class="row border-bottom">
                            <div class="col-1"><input type="radio"></div>
                            <div class="col-3"><small>2 Taksit</small></div>
                            <div class="col-3 text-center"><small>15,00 TL</small></div>
                            <div class="col-5 text-center"><small>1.265,00 TL</small></div>
                          </div>
                          <div class="row border-bottom">
                            <div class="col-1"><input type="radio"></div>
                            <div class="col-3"><small>3 Taksit</small></div>
                            <div class="col-3 text-center"><small>25,00 TL</small></div>
                            <div class="col-5 text-center"><small>1.275,00 TL</small></div>
                          </div>
                          <div class="row border-bottom">
                            <div class="col-1"><input type="radio"></div>
                            <div class="col-3"><small>6 Taksit</small></div>
                            <div class="col-3 text-center"><small>35,00 TL</small></div>
                            <div class="col-5 text-center"><small>1.285,00 TL</small></div>
                          </div>
                          <div class="row border-bottom">
                            <div class="col-1"><input type="radio"></div>
                            <div class="col-3"><small>9 Taksit</small></div>
                            <div class="col-3 text-center"><small>45,00 TL</small></div>
                            <div class="col-5 text-center"><small>1.295,00 TL</small></div>
                          </div>
                        </div>

                      </div>
                    </div>
                </div>
                <button class="btn btn-sm btn-primary float-right" @click="onClickStep(1)">Devam Et <i class="fas fa-angle-double-right"></i></button>
                <div class="clearfix"></div>
            </div>
            <div class="pr-3 pl-3 pb-3" v-show="stepper.stepBlock2">
              <h3>Eğitmen Seçimi</h3>
              <div class="mb-2">
                <div id="accordionLessonTime">
                  <div class="card card-secondary">
                    <div class="card-header">
                      <h4 class="card-title w-100">
                        <a class="d-block w-100" data-toggle="collapse" href="#minute60">
                          <i class="far fa-clock"></i> 60 Dakika Boşluk Olan Eğitmenler
                        </a>
                      </h4>
                    </div>
                    <div id="minute60" class="collapse show" data-parent="#accordionLessonTime">
                      <div class="card-body">
                          <button type="button" class="btn btn-outline-secondary btn-sm m-1"><i class="nav-icon fas fa-venus text-danger"></i> Teacher Neriman<br />00:00</button>
                          <button type="button" class="btn btn-outline-secondary btn-sm m-1"><i class="nav-icon fas fa-venus text-danger"></i> Teacher Neriman<br />00:10</button>
                          <button type="button" class="btn btn-outline-secondary btn-sm m-1"><i class="nav-icon fas fa-venus text-danger"></i> Teacher Neriman<br />00:20</button>
                          <button type="button" class="btn btn-outline-secondary btn-sm m-1"><i class="nav-icon fas fa-mars text-primary"></i> Teacher Alex<br />01:00</button>
                      </div>
                    </div>
                  </div>
                  <div class="card card-secondary">
                    <div class="card-header">
                      <h4 class="card-title w-100">
                        <a class="d-block w-100" data-toggle="collapse" href="#minute50">
                          <i class="far fa-clock"></i> 50 Dakika Boşluk Olan Eğitmenler
                        </a>
                      </h4>
                    </div>
                    <div id="minute50" class="collapse" data-parent="#accordionLessonTime">
                      <div class="card-body">
                          <button type="button" class="btn btn-outline-secondary m-1"><i class="nav-icon fas fa-venus text-danger"></i> Teacher Neriman<br />00:00</button>
                          <button type="button" class="btn btn-outline-secondary m-1"><i class="nav-icon fas fa-venus text-danger"></i> Teacher Neriman<br />00:10</button>
                          <button type="button" class="btn btn-outline-secondary m-1"><i class="nav-icon fas fa-venus text-danger"></i> Teacher Neriman<br />00:20</button>
                          <button type="button" class="btn btn-outline-secondary m-1"><i class="nav-icon fas fa-mars text-primary"></i> Teacher Alex<br />01:00</button>
                      </div>
                    </div>
                  </div>
                  <div class="card card-secondary">
                    <div class="card-header">
                      <h4 class="card-title w-100">
                        <a class="d-block w-100" data-toggle="collapse" href="#minute40">
                          <i class="far fa-clock"></i> 40 Dakika Boşluk Olan Eğitmenler
                        </a>
                      </h4>
                    </div>
                    <div id="minute40" class="collapse" data-parent="#accordionLessonTime">
                      <div class="card-body">
                          <button type="button" class="btn btn-outline-secondary m-1"><i class="nav-icon fas fa-venus text-danger"></i> Teacher Neriman<br />00:00</button>
                          <button type="button" class="btn btn-outline-secondary m-1"><i class="nav-icon fas fa-venus text-danger"></i> Teacher Neriman<br />00:10</button>
                          <button type="button" class="btn btn-outline-secondary m-1"><i class="nav-icon fas fa-venus text-danger"></i> Teacher Neriman<br />00:20</button>
                          <button type="button" class="btn btn-outline-secondary m-1"><i class="nav-icon fas fa-mars text-primary"></i> Teacher Alex<br />01:00</button>
                      </div>
                    </div>
                  </div>
                  <div class="card card-secondary">
                    <div class="card-header">
                      <h4 class="card-title w-100">
                        <a class="d-block w-100" data-toggle="collapse" href="#minute30">
                          <i class="far fa-clock"></i> 30 Dakika Boşluk Olan Eğitmenler
                        </a>
                      </h4>
                    </div>
                    <div id="minute30" class="collapse" data-parent="#accordionLessonTime">
                      <div class="card-body">
                          <button type="button" class="btn btn-outline-secondary m-1"><i class="nav-icon fas fa-venus text-danger"></i> Teacher Neriman<br />00:00</button>
                          <button type="button" class="btn btn-outline-secondary m-1"><i class="nav-icon fas fa-venus text-danger"></i> Teacher Neriman<br />00:10</button>
                          <button type="button" class="btn btn-outline-secondary m-1"><i class="nav-icon fas fa-venus text-danger"></i> Teacher Neriman<br />00:20</button>
                          <button type="button" class="btn btn-outline-secondary m-1"><i class="nav-icon fas fa-mars text-primary"></i> Teacher Alex<br />01:00</button>
                      </div>
                    </div>
                  </div>
                  <div class="card card-secondary">
                    <div class="card-header">
                      <h4 class="card-title w-100">
                        <a class="d-block w-100" data-toggle="collapse" href="#minute20">
                          <i class="far fa-clock"></i> 20 Dakika Boşluk Olan Eğitmenler
                        </a>
                      </h4>
                    </div>
                    <div id="minute20" class="collapse" data-parent="#accordionLessonTime">
                      <div class="card-body">
                          <button type="button" class="btn btn-outline-secondary m-1"><i class="nav-icon fas fa-venus text-danger"></i> Teacher Neriman<br />00:00</button>
                          <button type="button" class="btn btn-outline-secondary m-1"><i class="nav-icon fas fa-venus text-danger"></i> Teacher Neriman<br />00:10</button>
                          <button type="button" class="btn btn-outline-secondary m-1"><i class="nav-icon fas fa-venus text-danger"></i> Teacher Neriman<br />00:20</button>
                          <button type="button" class="btn btn-outline-secondary m-1"><i class="nav-icon fas fa-mars text-primary"></i> Teacher Alex<br />01:00</button>
                      </div>
                    </div>
                  </div>
                  <div class="card card-secondary">
                    <div class="card-header">
                      <h4 class="card-title w-100">
                        <a class="d-block w-100" data-toggle="collapse" href="#minute10">
                          <i class="far fa-clock"></i> 10 Dakika Boşluk Olan Eğitmenler
                        </a>
                      </h4>
                    </div>
                    <div id="minute10" class="collapse" data-parent="#accordionLessonTime">
                      <div class="card-body">
                          <button type="button" class="btn btn-outline-secondary m-1"><i class="nav-icon fas fa-venus text-danger"></i> Teacher Neriman<br />00:00</button>
                          <button type="button" class="btn btn-outline-secondary m-1"><i class="nav-icon fas fa-venus text-danger"></i> Teacher Neriman<br />00:10</button>
                          <button type="button" class="btn btn-outline-secondary m-1"><i class="nav-icon fas fa-venus text-danger"></i> Teacher Neriman<br />00:20</button>
                          <button type="button" class="btn btn-outline-secondary m-1"><i class="nav-icon fas fa-mars text-primary"></i> Teacher Alex<br />01:00</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-sm btn-primary float-left" @click="onClickStep(-1)"><i class="fas fa-angle-double-left"></i> Geri</button>
              <button class="btn btn-sm btn-primary float-right" @click="onClickStep(1)">Devam Et <i class="fas fa-angle-double-right"></i></button>
              <div class="float-right mr-2 h5 mt-1">Teacher Alex 10:50</div>
              <div class="clearfix"></div>
            </div>
            <div class="pr-3 pl-3 pb-3" v-show="stepper.stepBlock3">
              <h3>Ödeme</h3>
              <div class="mb-2">
                  <div class="row">
                    <div class="col-5">
                      <div id="accordionPay">
                        <div class="card card-secondary">
                          <div class="card-header">
                            <h4 class="card-title w-100">
                              <a class="d-block w-100" data-toggle="collapse" href="#collapseOne">
                                <i class="far fa-credit-card"></i> Kredi Kartı
                              </a>
                            </h4>
                          </div>
                          <div id="collapseOne" class="collapse show" data-parent="#accordionPay">
                            <div class="card-body">
                                <div class="form-group">
                                  <input type="text" class="form-control" placeholder="Kart Sahibi">
                                </div>
                                <div class="form-group">
                                  <input type="text" class="form-control" placeholder="Kart Numarası">
                                </div>
                                <div class="row">
                                  <div class="col-6">
                                    <div class="form-group">
                                      <input type="text" class="form-control" placeholder="29/2022">
                                    </div>
                                  </div>
                                  <div class="col-6">
                                    <div class="form-group">
                                      <input type="text" class="form-control" placeholder="000">
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-7">
                                    <div class="form-check">
                                      <input type="checkbox" class="form-check-input">
                                      <label class="form-check-label">3D Secure</label>
                                    </div>
                                  </div>
                                  <div class="col-5">
                                    <div class="form-group">
                                      <button class="btn btn-default">Ödeme Yap</button>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div class="card card-secondary">
                          <div class="card-header">
                            <h4 class="card-title w-100">
                              <a class="d-block w-100" data-toggle="collapse" href="#collapseTwo">
                                <i class="fas fa-university"></i> Havale
                              </a>
                            </h4>
                          </div>
                          <div id="collapseTwo" class="collapse" data-parent="#accordionPay">
                            <div class="card-body"></div>
                          </div>
                        </div>
                        <div class="card card-secondary">
                          <div class="card-header">
                            <h4 class="card-title w-100">
                              <a class="d-block w-100" data-toggle="collapse" href="#collapseThree">
                                <i class="fas fa-envelope"></i> Ödeme Linki
                              </a>
                            </h4>
                          </div>
                          <div id="collapseThree" class="collapse" data-parent="#accordionPay">
                            <div class="card-body"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-7 text-center">
                        <h4>3D Secure ya da İşlem durum bilgisi verilen yer.</h4>
                    </div>
                  </div>
              </div>
              <button class="btn btn-sm btn-primary float-left" @click="onClickStep(-1)"><i class="fas fa-angle-double-left"></i> Geri</button>
              <button class="btn btn-sm btn-primary float-right" @click="onClickStep(1)">İşlemi Tamamla <i class="fas fa-angle-double-right"></i></button>
              <div class="clearfix"></div>
            </div>
            <div class="pr-3 pl-3 pb-3" v-show="stepper.stepBlock4">
              <h3></h3>
              <div class="mb-2 text-center">
                  <h1 class="text-success mt-5"><i class="fas fa-check-circle"></i></h1>
                  <h3 class="mb-5">İşlemler başarıyla tamamlandı.</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal AddRemoveLessonModal -->
    <div class="modal fade" id="AddRemoveLessonModal" tabindex="1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Ders İşlemleri</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Form @submit="SaveAddRemoveLessonForm" :validation-schema="schemaAddRemoveLessonForm" ref="AddRemoveLessonForm">
              <div class="modal-body">
                <div class="row">
                  <div class="col-6">
                    <Field type="number" class="form-control" id="NumberOfLesson" name="NumberOfLesson"/>
                    <small><ErrorMessage name="NumberOfLesson" class="error-feedback"/></small>
                  </div>
                  <div class="col-6">
                    <Field class="form-control" name="LessonProcess" id="LessonProcess" as="select">
                      <option value=""></option>
                      <option value="remove">Sil</option>
                      <option value="add">Ekle</option>
                    </Field>
                    <small><ErrorMessage name="LessonProcess" class="error-feedback"/></small>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-primary" :disabled="modalSubmitLoading">
                  <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                  <span class="ml-2">İşlemi Yap</span>
                </button>
              </div>
          </Form>
        </div>
      </div>
    </div>

    <!-- Modal ChangeTeacherModal -->
    <div class="modal fade" id="ChangeTeacherModal" tabindex="1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Eğitmen Değiştir</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="mb-2"><i><strong>Haftada {{ changeTeacher.selectedTrainingWeekday }} gün, {{changeTeacher.selectedTrainingDuration}} dakika</strong> eğitim.</i></div>
            <TeacherWorkTimes :Times="teacherTimes" :TrianingWeekDay="changeTeacher.selectedTrainingWeekday" @SelectedTeacherTime="SelectedTeacherTime($event)"></TeacherWorkTimes>
            <!-- <div class="overflow-auto mb-1" style="height:400px;">
              <div class="text-center fa-2x" v-show="dataLoading"><i class="fas fa-solid fa-circle-notch fa-spin"></i></div>
              <button type="button" class="btn btn-outline-secondary btn-sm m-1" style="width:110px;" v-for="t in teacherTimes.filter(x=>x.duration===changeTeacher.selectedTrainingDuration && !((x.id==changeTeacher.selectedTrainingTeacherId &&x.time== changeTeacher.selectedTrainingTime))).sort((a,b)=>{return parseInt(a.showTime.replace(':',''))-parseInt(b.showTime.replace(':',''));})" :key="t" @click="changeTeacher.selectedTeacher=t">
                <i class="nav-icon fas fa-sm" v-if="t.gender>0" :class="{'fa-mars text-primary':t.gender==1,'fa-venus text-danger':t.gender==2}"></i> {{t.name}}<br />{{t.showTime}}
              </button>
            </div>   -->
          </div>
          <div class="modal-footer">
            <div class="h5">{{changeTeacher.selectedTeacher.name}} {{changeTeacher.selectedTeacher.showTime}}</div>
            <button type="button" class="btn btn-primary" :disabled="modalSubmitLoading" @click="SaveChangeTeacherTime()">
              <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
              <span class="ml-2">Eğitmeni Değiştir</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal ViewScheduleByDay -->
    <div class="modal fade" id="ViewScheduleByDay" tabindex="1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle"><span class="text-capitalize">{{viewScheduleByDayModel.memberName}} {{ viewScheduleByDayModel.memberSurname }}</span> {{ viewScheduleByDayModel.lessonDate }} tarihli dersi</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-2">
                <div class="col-sm-12 col-md-2 col-lg-2">
                  <label>Ders Durumu : </label>
                </div>
                <div class="col-sm-12 col-md-2 col-lg-2">
                  <div class="form-group">
                    <div class="form-check">
                      <input type="radio" id="statusComplated" value="2" v-model="viewScheduleByDayModel.scheduleStatus" class="form-check-input" >
                      <label class="form-check-label" for="statusComplated">Derse Katıldı</label>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3">
                  <div class="form-group">
                    <div class="form-check">
                      <input type="radio" id="statusCancel" value="1" v-model="viewScheduleByDayModel.scheduleStatus" class="form-check-input" >
                      <label class="form-check-label" for="statusCancel">Derse Katılmadı</label>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-5 col-lg-5">
                  <div class="form-group">
                    <div class="form-check">
                      <input type="radio" id="statusPostpone" value="3" v-model="viewScheduleByDayModel.scheduleStatus" class="form-check-input" >
                      <label class="form-check-label" for="statusPostpone">Dersi Ertele</label>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6" v-if="viewScheduleByDayModel.scheduleStatus==1 || viewScheduleByDayModel.scheduleStatus==3">
                  <div class="form-group">
                    <div class="form-check">
                      <input type="checkbox" id="HardProcess" v-model="viewScheduleByDayModel.hardProcess" class="form-check-input" >
                      <label class="form-check-label" for="HardProcess">Sadece seçili ders için işlem yap.</label>
                    </div>
                  </div>
                  <div class="text-sm text-danger"><span v-if="viewScheduleByDayModel.scheduleStatus==3">Öğrencinin ders hakkından düşmez.</span> Ders konusu kaydırmaz. Sadece dersin durumunu değişitir.</div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6" v-if="viewScheduleByDayModel.scheduleStatus==3">
                  <div class="form-group">
                    <div class="form-check">
                      <input type="checkbox" id="UsePostponeRight" v-model="viewScheduleByDayModel.usePostponeRight" class="form-check-input" :disabled="viewScheduleByDayModel.hardProcess" >
                      <label class="form-check-label" for="UsePostponeRight">Postpone hakkından düşülsün.</label>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6" v-if="viewScheduleByDayModel.scheduleStatus==1 || viewScheduleByDayModel.scheduleStatus==2">
                    <select class="form-control" v-model="viewScheduleByDayModel.teacherId">
                      <option value=""></option>
                      <option v-for="teacher in viewScheduleByDayModel.activeTeacherList" :key="teacher" :value="teacher.id">{{teacher.name}} {{teacher.surname}} ({{teacher.nickname}})</option>
                    </select>
                </div>
            </div>
            
            <div class="row mb-5">
              <div class="col-12">
                <label>Eğitmen Görüşü ve Tavsiyeleri</label>
                <div style="height:120px">
                  <quill-editor v-model:value="viewScheduleByDayModel.note" :options="{modules:{toolbar:[['bold','italic','underline','strike'],[{align:[]}]]}}"/>
                </div>
              </div>
            </div>
            
            <div class="row mb-3">
              <div class="col-12">
                <label>Yapılan hatalar ve doğruları</label>
                <div>
                  <div class="input-group mb-1" v-for="(c,index) in viewScheduleByDayModel.incorrectList" :key="c">
                    <div class="input-group-append">
                      <span class="input-group-text">{{ index+1 }}.</span>
                    </div>
                    <input type="text" class="form-control" placeholder="Hatayı yazınız" v-model="c.InCorrect" />
                    <input type="text" class="form-control" placeholder="Doğrusunu yazınız"  v-model="c.Correct">
                    <div class="input-group-prepend"><button class="btn btn-primary" @click="AddIncorrectList()" :disabled="viewScheduleByDayModel.incorrectList.length!=(index+1)">+</button></div>
                    <div class="input-group-prepend"><button class="btn btn-primary" @click="RemoveIncorrectList(index)" :disabled="index==0 || viewScheduleByDayModel.incorrectList.length!=(index+1)">-</button></div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <label>Ders Sonu Değerlendirme</label>
                <div class="table-responsive">
                  <table class="table mb-0">
                    <thead>
                      <tr>
                        <th class="text-sm">Dinleme</th>
                        <th class="text-sm">Okuma</th>
                        <th class="text-sm">Konuşma</th>
                        <th class="text-sm">Kelime B.</th>
                        <th class="text-sm">Dilbilgisi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><select v-model="viewScheduleByDayModel.listening" class="form-control form-control-sm"><option value="5">A</option><option value="4">B</option><option value="3">C</option><option value="2">D</option><option value="1">E</option></select></td>
                        <td><select v-model="viewScheduleByDayModel.reading" class="form-control form-control-sm"><option value="5">A</option><option value="4">B</option><option value="3">C</option><option value="2">D</option><option value="1">E</option></select></td>
                        <td><select v-model="viewScheduleByDayModel.speaking" class="form-control form-control-sm"><option value="5">A</option><option value="4">B</option><option value="3">C</option><option value="2">D</option><option value="1">E</option></select></td>
                        <td><select v-model="viewScheduleByDayModel.vocabulary" class="form-control form-control-sm"><option value="5">A</option><option value="4">B</option><option value="3">C</option><option value="2">D</option><option value="1">E</option></select></td>
                        <td><select v-model="viewScheduleByDayModel.grammar" class="form-control form-control-sm"><option value="5">A</option><option value="4">B</option><option value="3">C</option><option value="2">D</option><option value="1">E</option></select></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row mb-3" v-if="IsLevelBook">
                    <div class="col-sm-12 col-md-12 col-lg-4">
                      <strong>(*) Öğrencinin Seviyesi :</strong>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-8">
                      <select v-model="viewScheduleByDayModel.levelTestLessonResult" class="form-control form-control-sm">
                        <option value="-1">&nbsp;</option>
                        <option value="0">No Level</option>
                        <option value="1">A1 - Başlangıç (Beginner)</option>
                        <option value="2">A2 - Temel (Elementary)</option>
                        <option value="3">B1 - Orta seviye öncesi (Pre-Intermediate)</option>
                        <option value="4">B2 - Orta seviye (Intermediate)</option>
                        <option value="5">C1 - Orta seviyenin üstü (Upper-Indermediate)</option>
                        <option value="6">C2 - İleri (Advanced)</option>
                      </select>
                    </div>
                  </div>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" :disabled="modalSubmitLoading" data-dismiss="modal" aria-label="Close">İptal</button>
            <button type="button" class="btn btn-primary" :disabled="modalSubmitLoading" @click="SaveScheduleByDay()">
                <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm" ></span>
                <span class="ml-2" >Kaydet</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- <TaskModal :Visible="taskModalVisible" :MemberId="taskModalMemberId" @CloseTaskModal="OpenCloseTaskModal($event,null)"></TaskModal> -->
    <MemberTransactionModal :Visible="memberTransactionModalVisible" :MemberId="memberTransactionModalMemberId" @CloseMemberTransactionModal="OpenCloseMemberTransactionModal($event,null)"></MemberTransactionModal>
    <FreezeTrainingModal :Visible="freezeTrainingModalVisible" :MemberId="freezeTrainingModalMemberId" :TrainingFileId="freezeTrainingModalTrainingFileId" @CloseFreezeTrainingModal="OpenCloseFreezeTrainingModal($event,null,null)"></FreezeTrainingModal>
    <LessonSlideModal :Visible="lessonSlideModalVisible" :MemberId="lessonSlideModalMemberId" :TrainingFileId="lessonSlideModalTrainingFileId" @CloseLessonSlideModal="OpenCloseLessonSlideModal($event,null,null)"></LessonSlideModal>
    <UpdateStartDateModal :Visible="updateStartDateModalVisible" :MemberId="updateStartDateModalMemberId" :TrainingFileId="updateStartDateModalTrainingFileId" @CloseUpdateStartDateModal="OpenCloseUpdateStartDateModal($event,null,null)"></UpdateStartDateModal>
    <MemberProfileModal :Visible="memberProfileModalVisible" :MemberId="memberProfileModalMemberId" @CloseMemberProfileModal="OpenCloseMemberProfileModal($event,null)"></MemberProfileModal>
    <BookChangeUnitModal :Visible="bookChangeUnitModalVisible" :ChangeBookInfo="changeUnit" @RefreshSchedule="handlerRefreshSchedule()" @OpenCloseBookChangeUnitModal="handlerOpenCloseBookChangeUnitModal($event,null)"></BookChangeUnitModal>
</template>

<script>
import {mapActions} from "vuex"
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import {GetStatusText,GetTrainingFileStatus,GetRoleText,GetScheduleStatusText,GetLessonPuanText} from "../../../helpers/enum-convert-helper";
import {CreateLink} from "../../../helpers/link-create-helper";
import {TextConvert,GetDefaultMailTemplate} from "../../../helpers/notification-helper";
import {DateFormat,CalculateTime,CalculateDateTime} from "../../../helpers/date-convert-helper";
import {IsShowErrorMessage} from "../../../helpers/error-message-helper";
import {ParsePhoneNumber} from "../../../helpers/phone-helper";
import Editor from '@tinymce/tinymce-vue';
import { quillEditor } from 'vue3-quill';
import PaginationBST from '../../../components/Pagination.vue';
import TeacherWorkTimes from '../../../components/TeacherWorkTimes.vue';
//import TaskModal from '../../../components/TaskModal.vue';
import permissionMixins from "../../../mixins/permissons-mixin.js";
import MemberTransactionModal from '../../../components/MemberTransactionModal.vue';
import FreezeTrainingModal from '../../../components/FreezeTrainingModal.vue';
import LessonSlideModal from '../../../components/LessonSlideModal.vue';
import UpdateStartDateModal from '../../../components/UpdateStartDateModal.vue';
import MemberProfileModal from '../../../components/MemberProfile/MemberProfileModal.vue';
import BookChangeUnitModal from '../../../components/MemberProfile/Modals/BookChangeUnitModal.vue';
import {LEVELTESTBOOKID} from '../../../settings';
export default {
  name: "MemberStudent",
  emits:['CloseMemberProfileModal'],
  components: {
    Form,
    Field,
    ErrorMessage,
    'editor': Editor,
    quillEditor,
    PaginationBST,
    TeacherWorkTimes,
    //TaskModal,
    MemberTransactionModal,
    FreezeTrainingModal,
    LessonSlideModal,
    UpdateStartDateModal,
    MemberProfileModal,
    BookChangeUnitModal
  },
  data(){
    
    const schemaUpdateRightForm =  yup.object().shape({
      RightNumber: yup.number().required("Güncellemek istediğiniz adedi giriniz.").typeError('Lütfen bir sayı giriniz.').min(0,'En az 0 girebilirsiniz.').max(50,'En fazla 50 girebilirsiniz.')
    });

    const schemaSkypeAddressForm =  yup.object().shape({
      SkypeAddressSkype: yup.string().required("Skype adresi giriniz."),
    });

    const schemaNoteMemberForm =  yup.object().shape({
      NoteMemberFormContent: yup.string().required("Not giriniz."),
    });

    const schemaInfoMemberForm =  yup.object().shape({
      InfoMemberFormName: yup.string().required("Ad giriniz."),
      InfoMemberFormSurname: yup.string().required("Soyad giriniz."),
      InfoMemberFormGenderOption: yup.string().required("Cinsiyet giriniz."),
      InfoMemberFormId:yup.string()
    });

    const schemaContactEmailMemberForm = yup.object().shape({
      ContactEmailMemberFormEmail: yup.string().required("E-posta adresi giriniz."),
      ContactEmailMemberFormId:yup.string()
    });

    const schemaContactPhoneMemberForm = yup.object().shape({
      ContactPhoneMemberFormPhoneCode: yup.string().required("Ülke kodunu giriniz."),
      ContactPhoneMemberFormPhone: yup.string().required("Telefon numarası giriniz."),
      ContactPhoneMemberFormId:yup.string()
    });

    const schemaUserFormOperatorMemberForm = yup.object().shape({
      UserFormOperatorOption: yup.string().required("Form temsilcisi seçiniz."),
      UserFormOperatorMemberFormId:yup.string()
    });
    
    const schemaUserSaleMemberForm = yup.object().shape({
      UserSaleOption: yup.string().required("Satış temsilcisi seçiniz."),
      UserSaleMemberFormId:yup.string()
    });

    const schemaUserStudentOperatorMemberForm = yup.object().shape({
      UserStudentOperatorOption: yup.string().required("Öğrenci temsilcisi seçiniz."),
      UserStudentOperatorMemberFormId:yup.string()
    });

    const schemaChangeUnitForm = yup.object().shape({
      NewBookSubject: yup.string().required("Bir kitap konusu seçmelisiniz."),
      ChangeUnitType:yup.string().required("İşlem tipini seçmelisiniz.")
    });
    
    const schemaAddRemoveLessonForm = yup.object().shape({
      NumberOfLesson: yup.number().required("Ders adedi giriniz.").typeError('Lütfen bir sayı giriniz.').min(1,'En az 1 girebilirsiniz.').max(100,'En fazla 100 girebilirsiniz.'),
      LessonProcess:yup.string().required("İşlem seçmelisiniz.")
    });

    return{
      currentPage:0,
      items:[],
      pageSize:0,
      totalItems:0,
      totalPages:0,
      dataLoading:false,
      listType:null,
      listTitle:null,
      searchTextType:1,
      searchText:null,
      modalSubmitLoading:false,
      schemaNoteMemberForm,
      schemaInfoMemberForm,
      schemaContactEmailMemberForm,
      schemaContactPhoneMemberForm,
      schemaUserFormOperatorMemberForm,
      schemaUserSaleMemberForm,
      schemaUserStudentOperatorMemberForm,
      schemaChangeUnitForm,
      schemaAddRemoveLessonForm,
      schemaSkypeAddressForm,
      schemaUpdateRightForm,
      note:{
        memberId:null,
        dataLoading:false,
        currentPage:0,
        items:[],
        pageSize:0,
        totalItems:0,
        totalPages:0,
      },
      operationUsers:[],
      stepper:{
        currentStep:1,
        maxStep:4,
        stepBlock1:true,
        stepBlock2:false,
        stepBlock3:false,
        stepBlock4:false,
        progressValue:0,
      },
      scheduleCalender:{
        monthText:['Ocak','Şubat','Mart','Nisan','Mayıs','Haziran','Temmuz','Ağustos','Eylül','Ekim','Kasım','Aralık'],
        dayShortText:['PAZ','PZT','SAL','ÇAR','PER','CUM','CMT'],
        scheduleData:[],
        selectedMonth:parseInt(new Date().getMonth()),
        selectedYear:parseInt(new Date().getFullYear()),
        calender:[],
        nextLessonCount:0
      },
      selectedMemberId:null,
      changeUnit:{
        Date:null,
        Book:null,
        Level:null,
        Subject:null,
        TrainingFileId:null,
        BookId:null
      },
      // changeUnit:{
      //   selectedDate:null,
      //   selectedBook:null,
      //   selectedLevel:null,
      //   selectedSubject:null,
      //   selectedTrainingFileId:null,
      //   bookSubjectList:[],
      // },
      lessonResult:{
        trainingFileId: "",
        date: "",
        status: 0,
        book: {
          id: "",
          bookName: "",
          title: "",
          subject: "",
          words: [],
          contents: []
        },
        result: {
          note: "",
          listening: 0,
          reading: 0,
          speaking: 0,
          vocabulary: 0,
          grammar: 0
        }
      },
      addRemoveLesson:{
        selectedTrainingFileId:null,
        selectedMemberId:null
      },
      teacherTimes:[],
      changeTeacher:{
        selectedMemberId:null,
        selectedTrainingTeacherId:null,
        selectedTrainingTime:null,
        selectedTrainingDuration:null,
        selectedTrainingFileId:null,
        selectedTrainingWeekday:null,
        selectedTeacher:{}
      },
      updateSkypeAddress:{
        selectedMemberId:null,
        selectedTrainingTeacherId:null
      },
      updateRight:{
        selectedMemberId:null,
        selectedTrainingTeacherId:null,
        rightType:null
      },
      mailTemplates:[],
      smsTemplates:[],
      mailSendData:{
        id:0,
        to:"",
        memberId:"",
        subject:"",
        content:"",
        formSendLoading:false
      },
      smsSendData:{
        id:0,
        memberId:"",
        phoneNumber:"",
        content:"",
        formSendLoading:false
      },
      openMemberInfo:{},
      viewScheduleByDayModel:{
        activeTeacherList:[],
        memberId:'',
        trainingFileId:'',
        memberName:'',
        memberSurname:'',
        lessonDate:'',
        orginalLessonDate:'',
        scheduleStatus:0,
        teacherId:'',
        incorrectList:[],
        note:'',
        listening:0,
        reading:0,
        speaking:0, 
        vocabulary:0,
        grammar:0,
        levelTestLessonResult:-1,
        usePostponeRight:true
      },
      taskModalVisible:false,
      taskModalMemberId:null,
      memberTransactionModalVisible:false,
      memberTransactionModalMemberId:null,
      freezeTrainingModalVisible:false,
      freezeTrainingModalMemberId:null,
      freezeTrainingModalTrainingFileId:null,
      lessonSlideModalVisible:false,
      lessonSlideModalMemberId:null,
      lessonSlideModalTrainingFileId:null,
      updateStartDateModalVisible:null,
      updateStartDateModalMemberId:null,
      updateStartDateModalTrainingFileId:null,
      memberProfileModalVisible:false,
      memberProfileModalMemberId:null,

      bookChangeUnitModalVisible:false
    }
  },
  computed:{
    IsLevelBook:function(){
      if(!this.viewScheduleByDayModel)
        return false;

      return LEVELTESTBOOKID==this.viewScheduleByDayModel.bookId;
    },
    GetCalenderYear:function(){
      return this.scheduleCalender.selectedYear.toString();//.substr(-2, 4);
    },
    GetCalenderMonth:function(){
      return this.scheduleCalender.monthText[this.scheduleCalender.selectedMonth];
    },
    GetMainContentInLessonResult:function(){
       if(this.lessonResult!=null){
          let bookContents = this.lessonResult.book.contents;
          let book = bookContents.filter(x=>x.contentType==1)[0];
          if(book){
            return CreateLink(book.fileName);
          }
       }
       return null;
    },
    GetContent2InLessonResult:function(){
       if(this.lessonResult!=null){
          let bookContents = this.lessonResult.book.contents;
          let book = bookContents.filter(x=>x.contentType==2)[0];
          if(book){
            return CreateLink(book.fileName);
          }
       }
       return null;
    },
    GetExerciseInLessonResult:function(){
       if(this.lessonResult!=null){
          let bookContents = this.lessonResult.book.contents;
          let book = bookContents.filter(x=>x.contentType==3)[0];
          if(book){
            return CreateLink(book.fileName);
          }
       }
       return null;
    },
    GetVideoInLessonResult:function(){
       if(this.lessonResult!=null){
          let bookContents = this.lessonResult.book.contents;
          let book = bookContents.filter(x=>x.contentType==4)[0];
          if(book){
            return CreateLink(book.fileName);
          }
       }
       return null;
    }
  },
  watch:{
    "viewScheduleByDayModel.hardProcess":{
      handler(val){
        if(val){
          this.viewScheduleByDayModel.usePostponeRight=false;
        }else{
          this.viewScheduleByDayModel.usePostponeRight=true;
        }
      },
      deep: true 
    },
    "mailSendData.id":{
      handler(val){
        this.mailSendData.subject="";
        this.mailSendData.content="";
        let selected = this.mailTemplates.find(x=>x.id==val);
        if(selected){
          let parameter = {
              name: this.openMemberInfo.name,
              surname:this.openMemberInfo.surname,
              year: new Date().getFullYear()
          };
          this.mailSendData.subject=TextConvert(selected.subject,parameter);
          this.mailSendData.content=TextConvert(selected.body,parameter);
        }else if(val==99999){
          this.mailSendData.subject="Lengedu İngilizce Eğitim";
          let htmlData = GetDefaultMailTemplate();
          let parameter = {
              name: this.openMemberInfo.name,
              surname:this.openMemberInfo.surname,
              year: new Date().getFullYear()
          };
          this.mailSendData.content=TextConvert(htmlData,parameter);
        }else {
          this.mailSendData.subject='';
          this.mailSendData.content='';
        }
     },
     deep: true
    },
    "smsSendData.id":{
      handler(val){
        let selected = this.smsTemplates.find(x=>x.id==val);
        if(selected){
          let parameter = {
              name: this.openMemberInfo.name,
              surname:this.openMemberInfo.surname,
          };

          this.smsSendData.content=TextConvert(selected.body,parameter);
        }else if(val==99999){
          let htmlData = "Merhaba %NAME% %SURNAME%";
          let parameter = {
              name: this.openMemberInfo.name,
              surname:this.openMemberInfo.surname,
              year: new Date().getFullYear()
          };
          this.smsSendData.content=TextConvert(htmlData,parameter);
        }else{
          this.smsSendData.content='';
        }
     },
     deep: true
    }
  },
  methods: {
    ...mapActions([
      "MEMBER_NOTELIST","MEMBER_SAVENOTE",
      "MEMBER_INFOSAVE","MEMBER_EMAILSAVE","MEMBER_PHONESAVE",
      "MEMBER_GETOPERATIONUSERSFORCHANGEUSER","MEMBER_FORMOPERATORSAVE","MEMBER_SALESAVE",
      "MEMBER_STUDENTOPERATORSAVE","MEMBER_ACTIVESTUDENTLIST","MEMBER_SEARCHSTUDENTLIST","MEMBER_COMPLATEDSTUDENTLIST",
      "MEMBER_FREEZEEDUCATIONSTUDENTLIST","MEMBER_FINDTEACHERSTUDENTLIST","MEMBER_BREAKTIMESTUDENTLIST",
      "MEMBER_INACTIVESTUDENTLIST","TRAINING_GETSCHEDULEFORPANEL","TRAINING_GETBOOKWITHSUBJECT","TRAINING_CHANGEBOOKINSCHEDULEFORPANEL",
      "TRAINING_GETLESSONINSCHEDULEFORPANEL","TRAINING_ADDORREMOVELESSONINSCHEDULEFORPANEL","TRAINING_TEACHERTIMELIST",
      "TRAINING_CHANGETEACHERTIMEFORPANEL","TRAINING_UPDATESKYPEADDRESSSAVE",
      "TRAINING_UPDATERIGHTCHANGETEACHERSAVE","TRAINING_UPDATERIGHTPOSTPONESAVE","TRAINING_UPDATERIGHTMISSEDSAVE","TRAINING_UPDATERIGHTFREEZELESSONSAVE",
      "SEND_MAIL","SEND_SMS","GETMAILTEMPLATES_FORSTUDENT","GETSMSTEMPLATES_FORSTUDENT",
      "TRAINING_GETLESSON","TRAINING_GETLESSONBYDATE","TEACHER_ACTIVELIST","TRAINING_SAVELESSONBYDATE","TEACHER_GETAVAILABLEHOURSALLTEACHER","MEMBER_TRYYOURSELFLIST",
      "MEMBER_DEMOSTUDENT"
      ]),

    OpenViewScheduleByDay(name,surname,lessonDate,memberId,trainingFileId){
      this.viewScheduleByDayModel.memberId=memberId;
      this.viewScheduleByDayModel.trainingFileId=trainingFileId;
      this.viewScheduleByDayModel.memberName=name;
      this.viewScheduleByDayModel.memberSurname=surname;
      this.viewScheduleByDayModel.lessonDate=this.DateFormatForListTitle(lessonDate);
      this.viewScheduleByDayModel.orginalLessonDate=lessonDate;
      this.viewScheduleByDayModel.usePostponeRight=true;

      this.viewScheduleByDayModel.scheduleStatus = 0;
      this.viewScheduleByDayModel.teacherId = '';
      this.viewScheduleByDayModel.bookId = '';
      this.viewScheduleByDayModel.note='';
      this.viewScheduleByDayModel.listening='';
      this.viewScheduleByDayModel.reading='';
      this.viewScheduleByDayModel.speaking='';
      this.viewScheduleByDayModel.vocabulary='';
      this.viewScheduleByDayModel.grammar='';
      this.viewScheduleByDayModel.levelTestLessonResult=-1;
      this.viewScheduleByDayModel.incorrectList=[];
      this.viewScheduleByDayModel.incorrectList.push({Correct:'',InCorrect:''});
      
      let mainThis = this;
      this.TRAINING_GETLESSONBYDATE({memberId,trainingFileId,lessonDate})
      .then((res)=>{
            mainThis.viewScheduleByDayModel.scheduleStatus = res.data.scheduleStatus;
            mainThis.viewScheduleByDayModel.teacherId = res.data.teacherId;
            mainThis.viewScheduleByDayModel.bookId = res.data.bookId;
            if(res.data.result!=null){
              mainThis.viewScheduleByDayModel.note=res.data.result.note;
              mainThis.viewScheduleByDayModel.listening=res.data.result.listening;
              mainThis.viewScheduleByDayModel.reading=res.data.result.reading;
              mainThis.viewScheduleByDayModel.speaking=res.data.result.speaking;
              mainThis.viewScheduleByDayModel.vocabulary=res.data.result.vocabulary;
              mainThis.viewScheduleByDayModel.grammar=res.data.result.grammar;
              mainThis.viewScheduleByDayModel.levelTestLessonResult=res.data.result.levelTestLessonResult;
              mainThis.viewScheduleByDayModel.incorrectList=res.data.result.inCorrects!=null?JSON.parse(res.data.result.inCorrects):[{Correct:'',InCorrect:''}];
            }
            window.$("#ViewScheduleByDay").modal({backdrop: 'static',keyboard: false, show: true});
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
        });
    },
    SaveScheduleByDay(){

      this.modalSubmitLoading = true;
      if(!this.viewScheduleByDayModel.scheduleStatus){
          this.$toast.error('Ders durumunu seçmelisiniz.',{position : 'top',duration : 3000});
          this.modalSubmitLoading = false;
          return;
      }

      if(this.viewScheduleByDayModel.scheduleStatus==2 || this.viewScheduleByDayModel.scheduleStatus==1){
        if(!this.viewScheduleByDayModel.teacherId){
          this.$toast.error('Derse katılan eğitmeni seçiniz.',{position : 'top',duration : 3000});
          this.modalSubmitLoading = false;
          return;
        }
      }


      if(this.viewScheduleByDayModel.scheduleStatus==2){
        
        if(this.viewScheduleByDayModel.note.length<30){
          this.$toast.error('"Eğitmen Görüşü ve Tavsiyeleri" alanına bir yazı girmelisiniz.',{position : 'top',duration : 3000});
          this.modalSubmitLoading = false;
          return;
        }
        
        if(this.viewScheduleByDayModel.incorrectList.length==0){
          this.$toast.error('"Yapılan hatalar ve doğruları" bölümünü doldurmalısınız.',{position : 'top',duration : 3000});
          this.modalSubmitLoading = false;
          return;
        }else{
          let status=false;
          for (let i = 0; i < this.viewScheduleByDayModel.incorrectList.length; i++) {
            if((this.viewScheduleByDayModel.incorrectList[i].Correct.length<3) || (this.viewScheduleByDayModel.incorrectList[i].InCorrect.length<3)){
              this.$toast.error((i+1) + '. bölümü boş bıraktınız.',{position : 'top',duration : 3000});
              status=true;
              break;
            }
          }
          if(status){
            this.modalSubmitLoading = false;
            return;
          }
        }

        

        if(this.viewScheduleByDayModel.listening==0 && this.viewScheduleByDayModel.reading==0 && this.viewScheduleByDayModel.speaking==0 && this.viewScheduleByDayModel.vocabulary==0 && this.viewScheduleByDayModel.grammar==0){
          this.$toast.error('"Ders Sonu Değerlendirme" bölümünden en az bir tane seçmelisiniz.',{position : 'top',duration : 3000});
          this.modalSubmitLoading = false;
          return;
        }

        if(this.IsLevelBook && this.viewScheduleByDayModel.levelTestLessonResult==-1){
          this.$toast.error('Öğrencinin İngilizce seviyesini giriniz.',{position : 'top',duration : 3000});
          this.modalSubmitLoading = false;
          return;
        }
      }


      if(!confirm('Ders durumunu değiştirdiğinizde eğitmenin girmiş olduğu yorumu silebilirsiniz. Bilgileri kaydetmek istiyor musunuz?')){
        this.modalSubmitLoading = false;
        return;
      }
      let model = {
        memberId:this.viewScheduleByDayModel.memberId,
        trainingFileId:this.viewScheduleByDayModel.trainingFileId,
        lessonDate:this.viewScheduleByDayModel.orginalLessonDate,
        scheduleStatus:this.viewScheduleByDayModel.scheduleStatus,
        teacherId:this.viewScheduleByDayModel.teacherId,
        incorrectList:this.viewScheduleByDayModel.incorrectList,
        note:this.viewScheduleByDayModel.note,
        listening:this.viewScheduleByDayModel.listening,
        reading:this.viewScheduleByDayModel.reading,
        speaking:this.viewScheduleByDayModel.speaking, 
        vocabulary:this.viewScheduleByDayModel.vocabulary,
        grammar:this.viewScheduleByDayModel.grammar,
        usePostponeRight : this.viewScheduleByDayModel.usePostponeRight,
        hardProcess : this.viewScheduleByDayModel.hardProcess,
        levelTestLessonResult:this.viewScheduleByDayModel.levelTestLessonResult
      }
      this.TRAINING_SAVELESSONBYDATE(model)
      .then(()=>{
          let message = "Başarıyla kaydedildi.";
          this.$toast.success(message,{
                position : 'top',
                duration : 3000
            });
            this.modalSubmitLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.modalSubmitLoading=false;
        });
    },
    AddIncorrectList(){
      this.viewScheduleByDayModel.incorrectList.push({Correct:'',InCorrect:''});
    },
    RemoveIncorrectList(index){
      this.viewScheduleByDayModel.incorrectList.splice(index,1);
    },


    OpenUpdateContactModal(memberId,trainingFileId){
      this.updateSkypeAddress.selectedMemberId=memberId;
      this.updateSkypeAddress.selectedTrainingTeacherId=trainingFileId;
      this.$refs.SkypeAddressForm.resetForm();
      window.$("#UpdateContactModal").modal('show');
    },
    OpenUpdateRightModal(memberId,trainingFileId,rightType){
      this.updateRight.selectedMemberId=memberId;
      this.updateRight.selectedTrainingTeacherId=trainingFileId;
      this.updateRight.rightType=rightType;
      this.$refs.UpdateRightForm.resetForm();
      window.$("#UpdateRightModal").modal('show');
    },
    OpenSMSModal(form){
      this.openMemberInfo = form;
      this.smsSendData.phoneNumber=form.phoneNumber;
      this.smsSendData.id=0;
      this.smsSendData.memberId=form.id;
      this.smsSendData.formSendLoading=false;
      this.smsSendData.content="";
      this.GetSmsTemplates();
    },
    OpenMailModal(form){
      this.openMemberInfo = form;
      this.mailSendData.to=form.eMail;
      this.mailSendData.id=0;
      this.mailSendData.memberId=form.id;
      this.mailSendData.formSendLoading=false;
      this.mailSendData.subject="";
      this.mailSendData.content="";
      this.GetMailTemplates();
    },
    GetMailTemplates() {
      this.GETMAILTEMPLATES_FORSTUDENT()
      .then((res)=>{
            this.mailTemplates=res.data;
            window.$("#NotifcationMailModal").modal('show');
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
        });
    },
    SendMail() {
      this.mailSendData.formSendLoading=true;
      if(!confirm(this.mailSendData.to+' mail adresine gönderim yapmak istiyor musunuz?'))
      {
        this.mailSendData.formSendLoading=false;
        return;
      }

      this.SEND_MAIL(this.mailSendData)
      .then(()=>{
          this.mailSendData.formSendLoading=false;
          let message = "Başarıyla gönderildi.";
          this.$toast.success(message,{
                position : 'top',
                duration : 3000
            });
        }).catch(error=>{
          this.mailSendData.formSendLoading=false;
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
        });
    },
    GetSmsTemplates() {
      this.GETSMSTEMPLATES_FORSTUDENT()
      .then((res)=>{
            this.smsTemplates=res.data;
            window.$("#NotifcationSmsModal").modal('show');
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
        });
    },
    SendSms() {
      this.smsSendData.formSendLoading=true;
      if(!confirm(this.smsSendData.phoneNumber+' telefon numarasına gönderim yapmak istiyor musunuz?'))
      {
        this.smsSendData.formSendLoading=false;
        return;
      }

      this.SEND_SMS(this.smsSendData)
      .then(()=>{
          this.smsSendData.formSendLoading=false;
          let message = "Başarıyla gönderildi.";
          this.$toast.success(message,{
                position : 'top',
                duration : 3000
            });
        }).catch(error=>{
          this.smsSendData.formSendLoading=false;
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
        });
    },
    OpenAddRemoveLessonModal(memberId,trainingFileId){
      this.addRemoveLesson.selectedTrainingFileId = trainingFileId;
      this.addRemoveLesson.selectedMemberId = memberId;
      this.$refs.AddRemoveLessonForm.resetForm();
      window.$("#AddRemoveLessonModal").modal('show');
    },
    OpenChangeTeacherModal(memberId,trainingFileId,lessonTime,duration,teacherId,weekday){
      this.changeTeacher.selectedTrainingFileId = trainingFileId;
      this.changeTeacher.selectedMemberId = memberId;
      this.changeTeacher.selectedTrainingTime = lessonTime;
      this.changeTeacher.selectedTrainingDuration = duration;
      this.changeTeacher.selectedTrainingTeacherId = teacherId;
      this.changeTeacher.selectedTrainingWeekday = weekday;
      window.$("#ChangeTeacherModal").modal('show');
      this.GetTeacherTimes();
    },
    SelectedTeacherTime(value){
        this.changeTeacher.selectedTeacher={
          id:value.id,
          name:value.name,
          time:value.time.substring(0,2)+':'+value.time.substring(2,4),
          showTime:value.showTime
        };
    },
    OpenNoteListModal(id){
        window.$("#NoteListModal").modal('show');
        this.note.memberId=id;
        this.GetMemberNote(1,id)
    },
    OpenUpdateInfoModal(id,name,surname,gender){
        this.$refs.InfoMemberForm.resetForm();
        this.$refs.InfoMemberForm.setFieldValue('InfoMemberFormGenderOption',gender);
        this.$refs.InfoMemberForm.setFieldValue('InfoMemberFormSurname',surname);
        this.$refs.InfoMemberForm.setFieldValue('InfoMemberFormName',name);
        this.$refs.InfoMemberForm.setFieldValue('InfoMemberFormId',id);
        window.$("#UpdateInfoModal").modal('show');
    },
    OpenUpdateContactInfoModal(id,eMail,phoneNumber){
        
        this.$refs.ContactEmailMemberForm.resetForm();
        this.$refs.ContactEmailMemberForm.setFieldValue('ContactEmailMemberFormEmail',eMail);
        this.$refs.ContactEmailMemberForm.setFieldValue('ContactEmailMemberFormId',id);
        
        let phone = ParsePhoneNumber(phoneNumber);
        
        this.$refs.ContactPhoneMemberForm.resetForm();
        this.$refs.ContactPhoneMemberForm.setFieldValue('ContactPhoneMemberFormPhoneCode',phone.code);
        this.$refs.ContactPhoneMemberForm.setFieldValue('ContactPhoneMemberFormPhone',phone.number);
        this.$refs.ContactPhoneMemberForm.setFieldValue('ContactPhoneMemberFormId',id);

        window.$("#UpdateContactInfoModal").modal('show');
    },
    OpenUpdateUserModal(id,members){
        this.$refs.UserStudentOperatorMemberForm.resetForm();
        this.$refs.UserFormOperatorMemberForm.resetForm();
        this.$refs.UserSaleMemberForm.resetForm();

        let studentOperator = members.filter(x=>x.role===2)[0].userId;
        let formOperator = members.filter(x=>x.role===3)[0].userId;
        let sales = members.filter(x=>x.role===4)[0].userId;
        this.GetOperationUsers(formOperator,studentOperator,sales);

        this.$refs.UserStudentOperatorMemberForm.setFieldValue('UserStudentOperatorMemberFormId',id);
        this.$refs.UserFormOperatorMemberForm.setFieldValue('UserFormOperatorMemberFormId',id);
        this.$refs.UserSaleMemberForm.setFieldValue('UserSaleMemberFormId',id);

    },
    OpenScheduleModal(id){
      this.selectedMemberId = id;
      this.scheduleCalender.selectedMonth=parseInt(new Date().getMonth());
      this.scheduleCalender.selectedYear=parseInt(new Date().getFullYear());
      this.GetSchedule();
    },
    OpenChangeUnitModal(dayNumber,bookName,level,bookSubject,selectedTrainingFileId){
      this.changeUnit.selectedDate=new Date(this.scheduleCalender.selectedYear,this.scheduleCalender.selectedMonth,dayNumber);
      this.changeUnit.selectedBook=bookName;
      this.changeUnit.selectedLevel=level;
      this.changeUnit.selectedSubject=bookSubject;
      this.changeUnit.selectedTrainingFileId = selectedTrainingFileId

      if(this.changeUnit.bookSubjectList.length==0){
          this.GetBookWithLevel();
      }

      window.$("#ChangeUnitModal").modal('show');
    },
    OpenLessonResultModal(dayNumber,selectedTrainingFileId){
      let lessonDate = new Date(this.scheduleCalender.selectedYear,this.scheduleCalender.selectedMonth,dayNumber)
      this.GetLessonResult(selectedTrainingFileId,lessonDate);
      window.$("#LessonResultModal").modal('show');
    },
    OpenPackageModal(){
      window.$("#PackageModal").modal('show');
    },
    OpenCloseTaskModal(value,memberId){
      this.taskModalVisible=value;
      this.taskModalMemberId=memberId;
    },
    OpenCloseMemberTransactionModal(value,memberId){
      this.memberTransactionModalVisible=value;
      this.memberTransactionModalMemberId=memberId;
    },
    OpenCloseFreezeTrainingModal(value,memberId,trainingFileId){
      this.freezeTrainingModalVisible=value;
      this.freezeTrainingModalMemberId=memberId;
      this.freezeTrainingModalTrainingFileId=trainingFileId;
    },
    OpenCloseLessonSlideModal(value,memberId,trainingFileId){
      this.lessonSlideModalVisible=value;
      this.lessonSlideModalMemberId=memberId;
      this.lessonSlideModalTrainingFileId=trainingFileId;
    },
    OpenCloseUpdateStartDateModal(value,memberId,trainingFileId){
      this.updateStartDateModalVisible=value;
      this.updateStartDateModalMemberId=memberId;
      this.updateStartDateModalTrainingFileId=trainingFileId;
    },
    OpenCloseMemberProfileModal(value,memberId){
      this.memberProfileModalVisible=value;
      this.memberProfileModalMemberId=memberId;
    },
    handlerOpenCloseBookChangeUnitModal(value,bookInfo){
      if(bookInfo!=null){
        this.changeUnit.Date=new Date(this.scheduleCalender.selectedYear,this.scheduleCalender.selectedMonth,bookInfo.dayNumber);
        this.changeUnit.Book=bookInfo.bookName;
        this.changeUnit.Level=bookInfo.bookLevel;
        this.changeUnit.Subject=bookInfo.bookSubject;
        this.changeUnit.TrainingFileId=bookInfo.trainingFileId;
        this.changeUnit.BookId=bookInfo.bookId;
        this.changeUnit.MemberId=this.selectedMemberId;
      }
      this.bookChangeUnitModalVisible =value;
    },
    handlerRefreshSchedule(){
      this.GetSchedule();
      this.CheckNowCalender();
    },


    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    StudentStatus(value){
      return GetStatusText(value);
    },
    ScheduleStatus(value){
      return GetScheduleStatusText(value);
    },
    TrainingFileStatus(value){
      return GetTrainingFileStatus(value);
    },
    Role(value){
      return GetRoleText(value);
    },
    LessonPuan(value){
      return GetLessonPuanText(value);
    },
    DateFormat(value){
      return DateFormat(value,'yyyy-mm-dd hh:MM:ss');
    },
    DateFormatForListTitle(value){
      return DateFormat(value,'dd.mm.yyyy');
    },
    CalculateTimeByOffset(value){
        return CalculateTime(value);
    },
    CalculateDateTimeByOffset(value){
        return CalculateDateTime(value);
    },
    ScheduleFindDateFormat(value){
      return DateFormat(value,'yyyy-mm-dd');
    },
    ScheduleDateFormat(value){
      return DateFormat(value,'dd/mm');
    },
    GetCalenderDay(value){
      let weekDay =  this.scheduleCalender.dayShortText[new Date(value).getDay()];
      return weekDay;
    },
    ListButtonClick(type){
      this.listType = type;
      this.GetMemberStudentList(1);
    },
    GetMemberStudentList(page){
        switch (this.listType) {
          case 'active':
            this.GetActiveStudent(page);
            break;
          case 'breaktime':
            this.GetBreakTimeStudent(page);
            break;
          case 'findteacher':
            this.GetFindTeacherStudent(page);
            break;
          case 'freezeeducation':
            this.GetFreezeEducationStudent(page);
            break;
          case 'inactive':
            this.GetInActiveStudent(page);
            break;
          case 'complated':
            this.GetComplatedStudent(page);
            break;
          case 'tryyourself':
            this.GetTryYourSelf(page);
            break;
          case 'demo':
            this.GetDemoStudent(page);
            break;
          case 'textSearch':
           {
             this.GetSearchStudent(page,this.searchTextType,this.searchText)
             break;
           }
          default:
            break;
        }
    },
    GetActiveStudent(page) {
      this.dataLoading=true;
      this.currentPage=0;
      this.items=[];
      this.pageSize=0;
      this.totalItems=0;
      this.totalPages=0;
      this.listTitle='Aktif Öğrenciler';
      if(!page) {  page=1; }
      
      this.MEMBER_ACTIVESTUDENTLIST({page})
      .then((res)=>{
            this.currentPage=res.data.currentPage;
            this.items = res.data.items;
            this.pageSize=res.data.pageSize;
            this.totalItems=res.data.totalItems;
            this.totalPages=res.data.totalPages;
            this.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },
    GetInActiveStudent(page) {
      this.dataLoading=true;
      this.currentPage=0;
      this.items=[];
      this.pageSize=0;
      this.totalItems=0;
      this.totalPages=0;
      this.listTitle='Pasif Öğrenciler';
      if(!page) {  page=1; }
      
      this.MEMBER_INACTIVESTUDENTLIST({page})
      .then((res)=>{
            this.currentPage=res.data.currentPage;
            this.items = res.data.items;
            this.pageSize=res.data.pageSize;
            this.totalItems=res.data.totalItems;
            this.totalPages=res.data.totalPages;
            this.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },
    GetComplatedStudent(page) {
      this.dataLoading=true;
      this.currentPage=0;
      this.items=[];
      this.pageSize=0;
      this.totalItems=0;
      this.totalPages=0;
      this.listTitle='Eğitimi Tamamlayan Öğrenciler';
      if(!page) {  page=1; }
      
      this.MEMBER_COMPLATEDSTUDENTLIST({page})
      .then((res)=>{
            this.currentPage=res.data.currentPage;
            this.items = res.data.items;
            this.pageSize=res.data.pageSize;
            this.totalItems=res.data.totalItems;
            this.totalPages=res.data.totalPages;
            this.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },
    GetTryYourSelf(page) {
      this.dataLoading=true;
      this.currentPage=0;
      this.items=[];
      this.pageSize=0;
      this.totalItems=0;
      this.totalPages=0;
      this.listTitle='Kendini Dene Paketi Alan Öğrenciler';
      if(!page) {  page=1; }
      
      this.MEMBER_TRYYOURSELFLIST({page})
      .then((res)=>{
            this.currentPage=res.data.currentPage;
            this.items = res.data.items;
            this.pageSize=res.data.pageSize;
            this.totalItems=res.data.totalItems;
            this.totalPages=res.data.totalPages;
            this.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },
    GetDemoStudent(page) {
      this.dataLoading=true;
      this.currentPage=0;
      this.items=[];
      this.pageSize=0;
      this.totalItems=0;
      this.totalPages=0;
      this.listTitle='Demo Panel Sunulan Öğrenciler';
      if(!page) {  page=1; }
      
      this.MEMBER_DEMOSTUDENT({page})
      .then((res)=>{
            this.currentPage=res.data.currentPage;
            this.items = res.data.items;
            this.pageSize=res.data.pageSize;
            this.totalItems=res.data.totalItems;
            this.totalPages=res.data.totalPages;
            this.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },
    GetBreakTimeStudent(page) {
      this.dataLoading=true;
      this.currentPage=0;
      this.items=[];
      this.pageSize=0;
      this.totalItems=0;
      this.totalPages=0;
      this.listTitle='Yedek Eğitmende Olan Öğrenciler';
      if(!page) {  page=1; }
      
      this.MEMBER_BREAKTIMESTUDENTLIST({page})
      .then((res)=>{
            this.currentPage=res.data.currentPage;
            this.items = res.data.items;
            this.pageSize=res.data.pageSize;
            this.totalItems=res.data.totalItems;
            this.totalPages=res.data.totalPages;
            this.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },
    GetFindTeacherStudent(page) {
      this.dataLoading=true;
      this.currentPage=0;
      this.items=[];
      this.pageSize=0;
      this.totalItems=0;
      this.totalPages=0;
      this.listTitle='Eğitmen Arayan Öğrenciler';
      if(!page) {  page=1; }
      
      this.MEMBER_FINDTEACHERSTUDENTLIST({page})
      .then((res)=>{
            this.currentPage=res.data.currentPage;
            this.items = res.data.items;
            this.pageSize=res.data.pageSize;
            this.totalItems=res.data.totalItems;
            this.totalPages=res.data.totalPages;
            this.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },
    GetFreezeEducationStudent(page) {
      this.dataLoading=true;
      this.currentPage=0;
      this.items=[];
      this.pageSize=0;
      this.totalItems=0;
      this.totalPages=0;
      this.listTitle='Ders Donduran Öğrenciler';
      if(!page) {  page=1; }
      
      this.MEMBER_FREEZEEDUCATIONSTUDENTLIST({page})
      .then((res)=>{
            this.currentPage=res.data.currentPage;
            this.items = res.data.items;
            this.pageSize=res.data.pageSize;
            this.totalItems=res.data.totalItems;
            this.totalPages=res.data.totalPages;
            this.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },
    GetSearchStudent(page,searchField,searchText) {
      this.dataLoading=true;
      this.currentPage=0;
      this.items=[];
      this.pageSize=0;
      this.totalItems=0;
      this.totalPages=0;
      this.listTitle='Öğrenci Arama';
      if(!page) {  page=1; }
      
      this.MEMBER_SEARCHSTUDENTLIST({page,searchField,searchText})
      .then((res)=>{
            this.currentPage=res.data.currentPage;
            this.items = res.data.items;
            this.pageSize=res.data.pageSize;
            this.totalItems=res.data.totalItems;
            this.totalPages=res.data.totalPages;
            this.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },
    GetMemberNote(page) {
      this.note.dataLoading=true;
      let memberId = this.note.memberId;
      if(!page) {  page=1; }

      this.MEMBER_NOTELIST({page,memberId})
      .then((res)=>{
            this.note.currentPage=res.data.currentPage;
            this.note.items = res.data.items;
            this.note.pageSize=res.data.pageSize;
            this.note.totalItems=res.data.totalItems;
            this.note.totalPages=res.data.totalPages;
            this.note.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.note.dataLoading=false;
        });
    },
    GetSchedule() {
      let memberId=this.selectedMemberId;
      this.TRAINING_GETSCHEDULEFORPANEL({memberId})
      .then((res)=>{
            this.scheduleCalender.scheduleData = res.data;
            this.CreateCalender();
            window.$("#ScheduleModal").modal('show');
            this.CheckNowCalender();
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
        });
    },
    GetTeacherTimes() {
      this.dataLoading=true;
      this.teacherTimes=[];
      this.TEACHER_GETAVAILABLEHOURSALLTEACHER()
      .then((res)=>{
            this.teacherTimes=res.data;
            this.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },
    GetOperationUsers(formOperator,studentOperator,sales) {
      this.MEMBER_GETOPERATIONUSERSFORCHANGEUSER()
      .then((res)=>{
            this.operationUsers=res.data;
            this.$refs.UserStudentOperatorMemberForm.setFieldValue('UserStudentOperatorOption',(studentOperator==null?'':studentOperator));
            this.$refs.UserFormOperatorMemberForm.setFieldValue('UserFormOperatorOption',(formOperator==null?'':formOperator));
            this.$refs.UserSaleMemberForm.setFieldValue('UserSaleOption',(sales==null?'':sales));
            window.$("#UpdateUserModal").modal('show');
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
        });
    },
    GetBookWithLevel() {
      this.TRAINING_GETBOOKWITHSUBJECT()
      .then((res)=>{
            this.changeUnit.bookSubjectList = res.data;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
        });
    },
    GetLessonResult(trainingFileId,lessonDate) {
      this.TRAINING_GETLESSONINSCHEDULEFORPANEL({
        memberId: this.selectedMemberId,
        trainingFileId: trainingFileId,
        lessonDate: this.ScheduleFindDateFormat(lessonDate)
      })
      .then((res)=>{
            this.lessonResult = res.data;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
        });
    },
    SaveNoteMemberForm(form){
        this.modalSubmitLoading = true;
        let id = this.note.memberId;
        let content = form.NoteMemberFormContent;
        this.MEMBER_SAVENOTE({
          memberId:id,
          content:content
          }
        )
        .then(()=>{
            this.modalSubmitLoading=false;
            this.$refs.NoteMemberForm.setFieldValue('NoteMemberFormContent','');
            let message = "Başarıyla kaydedildi.";
            this.$toast.success(message,{
                  position : 'top',
                  duration : 3000
              });
            
            this.GetMemberNote(1);
        })
        .catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
              this.modalSubmitLoading=false;
        });
    },

    SaveRightForm(form){
        this.modalSubmitLoading = true;
        let rightNumber = form.RightNumber;
        if(this.updateRight.rightType===1){
          this.TRAINING_UPDATERIGHTCHANGETEACHERSAVE({
            memberId:this.updateRight.selectedMemberId,
            trainingFileId:this.updateRight.selectedTrainingTeacherId,
            rightNumber:rightNumber,
            }
          )
          .then(()=>{
              this.modalSubmitLoading=false;
              window.$("#UpdateRightModal").modal('hide');
              let message = "Eğitim hakkı başarıyla kaydedildi.";
              this.$toast.success(message,{
                    position : 'top',
                    duration : 3000
                });
              
              this.GetMemberStudentList(this.currentPage);
          })
          .catch(error=>{
                if(this.IsShowErrorMessage(error)){
                  this.$toast.error(error,{
                      position : 'top',
                      duration : 3000
                  });
                }
                this.modalSubmitLoading=false;
          });
        }else if(this.updateRight.rightType===2){
          this.TRAINING_UPDATERIGHTPOSTPONESAVE({
            memberId:this.updateRight.selectedMemberId,
            trainingFileId:this.updateRight.selectedTrainingTeacherId,
            rightNumber:rightNumber,
            }
          )
          .then(()=>{
              this.modalSubmitLoading=false;
              window.$("#UpdateRightModal").modal('hide');
              let message = "Eğitim hakkı başarıyla kaydedildi.";
              this.$toast.success(message,{
                    position : 'top',
                    duration : 3000
                });
              
              this.GetMemberStudentList(this.currentPage);
          })
          .catch(error=>{
                if(this.IsShowErrorMessage(error)){
                  this.$toast.error(error,{
                      position : 'top',
                      duration : 3000
                  });
                }
                this.modalSubmitLoading=false;
          });
        }else if(this.updateRight.rightType===3){
          this.TRAINING_UPDATERIGHTMISSEDSAVE({
            memberId:this.updateRight.selectedMemberId,
            trainingFileId:this.updateRight.selectedTrainingTeacherId,
            rightNumber:rightNumber,
            }
          )
          .then(()=>{
              this.modalSubmitLoading=false;
              window.$("#UpdateRightModal").modal('hide');
              let message = "Eğitim hakkı başarıyla kaydedildi.";
              this.$toast.success(message,{
                    position : 'top',
                    duration : 3000
                });
              
              this.GetMemberStudentList(this.currentPage);
          })
          .catch(error=>{
                if(this.IsShowErrorMessage(error)){
                  this.$toast.error(error,{
                      position : 'top',
                      duration : 3000
                  });
                }
                this.modalSubmitLoading=false;
          });
        }else if(this.updateRight.rightType===4){
          this.TRAINING_UPDATERIGHTFREEZELESSONSAVE({
            memberId:this.updateRight.selectedMemberId,
            trainingFileId:this.updateRight.selectedTrainingTeacherId,
            rightNumber:rightNumber,
            }
          )
          .then(()=>{
              this.modalSubmitLoading=false;
              window.$("#UpdateRightModal").modal('hide');
              let message = "Eğitim hakkı başarıyla kaydedildi.";
              this.$toast.success(message,{
                    position : 'top',
                    duration : 3000
                });
              
              this.GetMemberStudentList(this.currentPage);
          })
          .catch(error=>{
                if(this.IsShowErrorMessage(error)){
                  this.$toast.error(error,{
                      position : 'top',
                      duration : 3000
                  });
                }
                this.modalSubmitLoading=false;
          });
        }
    },

    SaveSkypeAddressForm(form){
        this.modalSubmitLoading = true;
        let skypeAddress = form.SkypeAddressSkype;
        this.TRAINING_UPDATESKYPEADDRESSSAVE({
          memberId:this.updateSkypeAddress.selectedMemberId,
          trainingFileId:this.updateSkypeAddress.selectedTrainingTeacherId,
          skypeAddress:skypeAddress,
          }
        )
        .then(()=>{
            this.modalSubmitLoading=false;
            window.$("#UpdateContactModal").modal('hide');
            let message = "Bilgiler başarıyla kaydedildi.";
            this.$toast.success(message,{
                  position : 'top',
                  duration : 3000
              });
            
            this.GetMemberStudentList(this.currentPage);
        })
        .catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
              this.modalSubmitLoading=false;
          });
    },

    SaveInfoMemberForm(form){
        this.modalSubmitLoading = true;
        let id = form.InfoMemberFormId;
        let name = form.InfoMemberFormName;
        let surname = form.InfoMemberFormSurname;
        let gender = form.InfoMemberFormGenderOption;
        this.MEMBER_INFOSAVE({
          memberId:id,
          name:name,
          surname:surname,
          gender:gender
          }
        )
        .then(()=>{
            this.modalSubmitLoading=false;
            window.$("#UpdateInfoModal").modal('hide');
            let message = "Bilgiler başarıyla kaydedildi.";
            this.$toast.success(message,{
                  position : 'top',
                  duration : 3000
              });
            
            this.GetMemberStudentList(this.currentPage);
        })
        .catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
              this.modalSubmitLoading=false;
          });
    },

    SaveContactEmailMemberForm(form){
        this.modalSubmitLoading = true;
        let id = form.ContactEmailMemberFormId;
        let eMail = form.ContactEmailMemberFormEmail;
        this.MEMBER_EMAILSAVE({
          memberId:id,
          eMail:eMail,
          }
        )
        .then(()=>{
            this.modalSubmitLoading=false;
            let message = "E-posta bilgisi başarıyla kaydedildi.";
            this.$toast.success(message,{
                  position : 'top',
                  duration : 3000
              });
            this.GetMemberStudentList(this.currentPage);
        })
        .catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
              this.modalSubmitLoading=false;
          });
    },
    SaveContactPhoneMemberForm(form){
        this.modalSubmitLoading = true;
        let id = form.ContactPhoneMemberFormId;
        let phoneCode = form.ContactPhoneMemberFormPhoneCode;
        let phoneNumber = form.ContactPhoneMemberFormPhone;
        this.MEMBER_PHONESAVE({
          memberId:id,
          phoneCode:phoneCode,
          phoneNumber:phoneNumber,
          }
        )
        .then(()=>{
            this.modalSubmitLoading=false;
            let message = "Telefon bilgisi başarıyla kaydedildi.";
            this.$toast.success(message,{
                  position : 'top',
                  duration : 3000
              });
            this.GetMemberStudentList(this.currentPage);
        })
        .catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
              this.modalSubmitLoading=false;
          });
    },
    SaveUserStudentOperatorMemberForm(form){
      this.modalSubmitLoading = true;
      let id = form.UserStudentOperatorMemberFormId;
      let studentOperator = form.UserStudentOperatorOption;
      this.MEMBER_STUDENTOPERATORSAVE({
        memberId:id,
        userId:studentOperator,
        }
      )
      .then(()=>{
          this.modalSubmitLoading=false;
          let message = "Öğrenci temsilcisi başarıyla kaydedildi.";
          this.$toast.success(message,{
                position : 'top',
                duration : 3000
            });
          this.GetMemberStudentList(this.currentPage);
      })
      .catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.modalSubmitLoading=false;
        });
    },
    SaveUserSaleMemberForm(form){
      this.modalSubmitLoading = true;
      let id = form.UserSaleMemberFormId;
      let sale = form.UserSaleOption;
      this.MEMBER_SALESAVE({
        memberId:id,
        userId:sale,
        }
      )
      .then(()=>{
          this.modalSubmitLoading=false;
          let message = "Satış temsilcisi başarıyla kaydedildi.";
          this.$toast.success(message,{
                position : 'top',
                duration : 3000
            });
          this.GetMemberStudentList(this.currentPage);
      })
      .catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.modalSubmitLoading=false;
        });
    },
    SaveUserFormOperatorMemberForm(form){
      this.modalSubmitLoading = true;
      let id = form.UserFormOperatorMemberFormId;
      let formOperator = form.UserFormOperatorOption;
      this.MEMBER_FORMOPERATORSAVE({
        memberId:id,
        userId:formOperator,
        }
      )
      .then(()=>{
          this.modalSubmitLoading=false;
          let message = "Form temsilcisi başarıyla kaydedildi.";
          this.$toast.success(message,{
                position : 'top',
                duration : 3000
            });
          this.GetMemberStudentList(this.currentPage);
      })
      .catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.modalSubmitLoading=false;
        });
    },
    SaveChangeUnitForm(form){
      this.modalSubmitLoading = true;
      let changeType = form.ChangeUnitType;
      let bookName = parseInt(form.NewBookSubject.split('#')[0]);
      let bookSubjectId = form.NewBookSubject.split('#')[1];

      this.TRAINING_CHANGEBOOKINSCHEDULEFORPANEL({
        memberId: this.selectedMemberId,
        trainingFileId: this.changeUnit.selectedTrainingFileId,
        lessonDate: this.ScheduleFindDateFormat(this.changeUnit.selectedDate),
        bookName: bookName,
        bookSubjectId: bookSubjectId,
        isChangeOneLesson: (changeType==='ChangeOneLesson')
        }
      )
      .then(()=>{
          this.modalSubmitLoading=false;
          let message = "Konu başarıyla güncellendi.";
          this.$toast.success(message,{
                position : 'top',
                duration : 3000
            });
          this.GetSchedule();
          this.CheckNowCalender();
          window.$("#ChangeUnitModal").modal('hide');
      })
      .catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.modalSubmitLoading=false;
        });
    },
    SaveAddRemoveLessonForm(form){
        this.modalSubmitLoading = true;
        this.TRAINING_ADDORREMOVELESSONINSCHEDULEFORPANEL({
          memberId: this.addRemoveLesson.selectedMemberId,
          trainingFileId: this.addRemoveLesson.selectedTrainingFileId,
          processType: form.LessonProcess,
          numberOfLesson: form.NumberOfLesson
          }
        )
        .then(()=>{
            this.modalSubmitLoading=false;
            let message = "İşlem başarıyla gerçekleşti.";
            this.$toast.success(message,{
                  position : 'top',
                  duration : 3000
              });
        })
        .catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
              this.modalSubmitLoading=false;
        });

    },
    SaveChangeTeacherTime(){

        this.modalSubmitLoading = true;
        this.TRAINING_CHANGETEACHERTIMEFORPANEL({
          memberId: this.changeTeacher.selectedMemberId,
          trainingFileId: this.changeTeacher.selectedTrainingFileId,
          teacherId: this.changeTeacher.selectedTeacher.id,
          lessonTime: this.changeTeacher.selectedTeacher.time
          }
        )
        .then(()=>{
            this.modalSubmitLoading=false;
            let message = "İşlem başarıyla gerçekleşti.";
            this.$toast.success(message,{
                  position : 'top',
                  duration : 3000
              });
        })
        .catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
              this.modalSubmitLoading=false;
        });
    },
    onClickStep: function(step) {

      if(step==1){
        this.stepper.currentStep++;
        if(this.stepper.currentStep>this.stepper.maxStep)
        {
          this.stepper.currentStep=this.stepper.maxStep;
        }
      }else{
        this.stepper.currentStep--;
        if(this.stepper.currentStep<1)
        {
          this.stepper.currentStep=1;
        }
      }

      switch (this.stepper.currentStep) {
        case 1:
          this.stepper.stepBlock1=true;
          this.stepper.stepBlock2=false;
          this.stepper.stepBlock3=false;
          this.stepper.stepBlock4=false;
          this.stepper.progressValue=0;
          break;
        case 2:
          this.stepper.stepBlock1=false;
          this.stepper.stepBlock2=true;
          this.stepper.stepBlock3=false;
          this.stepper.stepBlock4=false;
          this.stepper.progressValue=33;
          break;
        case 3:
          this.stepper.stepBlock1=false;
          this.stepper.stepBlock2=false;
          this.stepper.stepBlock3=true;
          this.stepper.stepBlock4=false;
          this.stepper.progressValue=66;
          break;
        case 4:
          this.stepper.stepBlock1=false;
          this.stepper.stepBlock2=false;
          this.stepper.stepBlock3=false;
          this.stepper.stepBlock4=true;
          this.stepper.progressValue=100;
          break;
        default:
          break;
      }
    },
    onClickNextMonth(){
      let nextMonth = this.scheduleCalender.selectedMonth+1;
      if(nextMonth>11){
          this.scheduleCalender.selectedMonth=0;
          this.scheduleCalender.selectedYear++;
      }else{
          this.scheduleCalender.selectedMonth=nextMonth;
      }
      this.CreateCalender();
      this.CheckNowCalender();
    },
    onClickPrevMonth(){
      let nextMonth = this.scheduleCalender.selectedMonth-1;
      if(nextMonth<0){
          this.scheduleCalender.selectedMonth=11;
          this.scheduleCalender.selectedYear--;
      }else{
          this.scheduleCalender.selectedMonth=nextMonth;
      }
      this.CreateCalender();
      this.CheckNowCalender();
    },
    GetLessonsSummary(studentId,lessons,row,direction){
        if(direction!=0 && direction!=1)
          return;
        
        if(!lessons)
          return;
        
        if(lessons.length==0)
          return;

        let lessonDate='';
        if(lessons.length==1){
          lessonDate=lessons[0].date;
        }else{
          if(direction==0){
            lessonDate=lessons[0].date;
          }else{
            lessonDate = lessons[lessons.length-1].date;
          }
        }
        
        let mainThis = this;
        let model = {
          memberId:studentId,
          lessonDate:lessonDate.toString(),
          direction:direction
        }

        this.TRAINING_GETLESSON(model)
        .then((res)=>{
            if(res.data.length>0)
              mainThis.items[row].lastLessons  = res.data;
          }).catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
          });
    },
    CheckNowCalender(){
      let checkDate = new Date().getFullYear() +'-'+new Date().getMonth();
      let selectedDate = this.scheduleCalender.selectedYear + '-' + this.scheduleCalender.selectedMonth;
      if(checkDate===selectedDate){
        let anchor = '#day'+ (new Date().getDate()-1);
        setTimeout(function(){window.location.href=anchor;},1000);
      }else{
        let anchor = '#begincalender';
        setTimeout(function(){window.location.href=anchor;},1000);
      }
    },
    CreateCalender(){
      let firstDay = new Date(this.scheduleCalender.selectedYear, this.scheduleCalender.selectedMonth, 1);
      let lastDay = new Date(this.scheduleCalender.selectedYear, this.scheduleCalender.selectedMonth + 1, 0);
      let calender = [];

      this.scheduleCalender.nextLessonCount  = this.scheduleCalender.scheduleData.filter(x=>this.ScheduleFindDateFormat(x.date)>=this.ScheduleFindDateFormat(new Date()) && x.status===0).length;

      for(var i=firstDay;i<=lastDay;i.setDate(i.getDate()+1)){
        
        let bookLevel = "";
        let bookName = "";
        let bookSubject = "";
        let status = -1
        let bookId = "";
        let trainingFileId = "";

        let lesson = this.scheduleCalender.scheduleData.find(x=>this.ScheduleFindDateFormat(x.date)===this.ScheduleFindDateFormat(i));
        if(lesson){
            bookId = lesson.book.id;
            bookLevel = lesson.book.bookLevel;
            bookName = lesson.book.bookName;
            bookSubject = lesson.book.subject;
            status = lesson.status;
            trainingFileId = lesson.trainingFileId;
        }

        calender.push({
          dayNumber:i.getDate(),
          dayText : this.GetCalenderDay(i),
          date:i,
          level:bookLevel,
          bookName:bookName,
          bookSubject:bookSubject,
          status:status,
          statusText:this.ScheduleStatus(status),
          bookId:bookId,
          trainingFileId:trainingFileId,
        });
      }
      this.scheduleCalender.calender = calender;
    },
    GetActiveTeacher(){
      this.TEACHER_ACTIVELIST()
      .then((res)=>{
        this.viewScheduleByDayModel.activeTeacherList = res.data;
        }).catch(error=>{
           if(this.IsShowErrorMessage(error)){
             this.$toast.error(error,{
                 position : 'top',
                 duration : 3000
             });
           }
        });
    },
  },
   mounted: function() {
    this.GetActiveTeacher();
  },
  mixins:[permissionMixins]
};
</script>
<style scoped>
  .error-feedback { color: red;}
  .pointer:hover{cursor: pointer;}
</style>